import React, { Component, createRef } from 'react'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { httpRequest } from '../../../ui-utils/api'
import { Close } from '@mui/icons-material';
import SignatureCanvas from 'react-signature-canvas'
import { withStyles, } from "@mui/styles";
import {
    Typography, Grid, Button, Modal, Box, Snackbar, SnackbarContent, IconButton,Checkbox
} from '@mui/material'
//Qr Add 28/02/24
import { QRCode } from 'react-qrcode-logo';
import * as CryptoJS from 'crypto-js';
import backendHosts from '../../../ui-utils/apiConfig.js'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas: {
       background: '#ffffff'
   },
   shutter: {
       marginTop: '50px',
       width: '100%',
       height: '100vh',
       background: 'red',
       borderTopLeftRadius: '8px',
       borderTopRightRaduis: '8px',
   },


 
};

const styles = theme => ({
    page1card: {
        width: '100%',
        paddingLeft: '20%',
        background: '#1B2636',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '90px 16px',

        }
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 25px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 25px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    page2card_1: {
        width: '100%',
        paddingLeft: '0%',
        background: '#1B2636',

        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '52px 16px',
            marginLeft: '0%',

        }
    },
    //Qr Add 28/02/24
    button_card: {
        width: '30%',
        "@media only screen and (max-device-width: 480px)": {
            width: '45%',
        }
    },
    button_card1: {
        width: '30%',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'
        }
    }
})
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#F74542",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#F74542'
        }
    },
    checked: { color: "#F74542", }
}))(Checkbox);
 class signature extends Component {
    
    constructor(props) {
        super(props)

        this.signref = createRef();
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            resendstatus: false,
            emailid: '',
            otp: '',
            starttime: true,
        islodding: false,
            aadharnobuttonst: true,
            qrpage: 0,  //Qr Add 28/02/24
            qrlink: '' ,//Qr Add 28/02/24
            //update on 11/11/24 from true and false to 0 and 1
            skippayment:0//added on 08/11/2024 Skip payment option For Free campaign. 
        }



    }
    signpadclear() {
        this.signref.clear()
    }
//Add a new. Websocket integration on 23/07/2024
     startWebsocket() {
        var ws = new WebSocket(backendHosts.WSURL,null,50000,10)
        let rd1 = localStorage.getItem('reg_data') || '{}'
        const { skippayment}=this.state
    let rd2 = JSON.parse(rd1)
    let emailid = rd2.emailid
    let mobileno = rd2.mobileno
    let bankaccno=rd2.bankaccno//added on 08/11/2024 to check bank account no
        ws.onmessage = (e)=>{
          console.log('websocket message event:', e)
          let wsdata = JSON.parse(e.data)

                    console.log(wsdata.mobileno)
                    if (wsdata.mobileno === mobileno) {
                        //added on 08/11/2024 to skup payment in qr 
                        console.log("skip",skippayment)
                        if(skippayment===1)
                        {
                        window.ChangePageNo(11)
                        }else{
                            let type=0
                            if(bankaccno!==null)
                            {
                            type=0
                            
                            }else{
                                type=2
                            }
                          this.SkipPayment(mobileno,emailid,type)
                        }
                      
                        console.log(wsdata.wsstatus)
                    }
        }
        ws.onopen = () => {
            console.log('WebSocket connection established');
            ws.send(emailid);
            // sendMessage();
          };
        ws.onclose = ()=>{
          // connection closed, discard old websocket and create a new one in 5s
          ws = null
          console.log('WebSocket connection Closed');
          this.startWebsocket()
        }
      }
      
    closeContactModal = () => {
        this.setState({ showerr: false })
    }
    Continue_sign = async () => {
        let trimmedDataURL = ''
        let isok = 0
    trimmedDataURL = this.signref.getCanvas().toDataURL("image/jpeg", 100)
    
  
console.log('trimmedDataURL', trimmedDataURL.toString().length)
if (trimmedDataURL.toString().length < 7000) {
    isok = 1
    this.setState({ showerr: true, errorMsg: "Signature is too Short", mysnack: snackeror.myerror })

}
if (isok === 0) {
    let rd1 = localStorage.getItem('reg_data') || '{}'
    let rd2 = JSON.parse(rd1)
    let emailid = rd2.emailid
let mobileno = rd2.mobileno
let aadharno = rd2.aadharno
let bankaccno=rd2.bankaccno
//added on 08/11/2024 to skip payment if amount is zero
let newArray = rd2.subscription.map((currentValue, index, array) =>{
    if(currentValue.card===true)
    {
        if(currentValue.serivce==="Subscription" && currentValue.amount==0)
        {this.setState({skippayment:1}) }else{this.setState({skippayment:0})}////update on 11/11/24 from true and false to 0 and 1
    }
})
console.log('bankaccno',bankaccno)
let type=0
if(bankaccno!==null)
{
type=0

}else{
    type=2
}
this.setState({ islodding: true })
    let payload = {
        "sign_img": trimmedDataURL,
        "emailid": emailid,
        "mobileno": mobileno,
        "aadhar": aadharno.substring(8, 12)
    }
  
    try {
        const otp = await httpRequest({
            endPoint: `/api/verfy/signature`,
            method: "post",
            instance: "instanceTwo",
            requestBody: payload,
        });
        this.setState({ islodding: false })
        if (otp === "API_ERROR") {
            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
        }
        if (otp.status === 200) {
            if(this.state.skippayment===0)
            {window.ChangePageNo(11)}else{
           
               this.SkipPayment(mobileno,emailid,type)

        } }
    } catch (error) {
        this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
    }
}
    }
   
    CreateQR = async () => {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid = rd2.emailid
        let mobileno = rd2.mobileno
this.startWebsocket()
        let qrid = ""
        let payload = {

            "emailid": emailid,
            "mobileno": mobileno,
        }


        try {
            const qrid_data = await httpRequest({
                endPoint: `/api/qrid`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            if (qrid_data.status === 201) {
                this.setState({ showerr: true, errorMsg: qrid_data.message, mysnack: snackeror.myerror })
            }
            if (qrid_data.status === 200) {
                qrid = qrid_data.qrid
              /*  this.wsconnect()
                this.ws.onmessage = (event) => {
                    console.log("got message", event.data);

                    let wsdata = JSON.parse(event.data)

                    console.log(wsdata.mobileno)
                    if (wsdata.mobileno === mobileno) {
                        window.ChangePageNo(16)
                        console.log(wsdata.wsstatus)
                    }

                };

                this.ws.onclose = function (e) {
                    console.log('socket closed try again');
                    setTimeout(function () {
                        this.wsconnect();
                    }, 1000);
                }*/

                let dedata = CryptoJS.AES.encrypt(qrid, "963").toString();

                let qrlink = backendHosts.QR + "signature?refno=" + encodeURIComponent(dedata)


                console.log(qrlink)
                this.setState({ qrpage: 1, qrlink: qrlink })
            }
        } catch (error) {
            console.log(error)
            this.setState({ showerr: true, errorMsg: "", mysnack: snackeror.myerror })
        }


    }
//qr web-socket
    wsconnect() {
        this.ws.onopen = () => {
            console.log("opened");
            this.ws.send("test", 18000); // message to send on Websocket ready
        };

    }

    //adeed on 08/11/2024 Keep payment page for free registration 
    SkipPayment = async (mobileno,emailid,type) => {
        let backoffice = {
            "mobileno": mobileno,
            "emailid": emailid,
               "type": type
            }
        try {
            let payload={
                "plans":"Subscription",
                "mobileno": mobileno,
                "emailid": emailid,
                "amount": 0
            }
            const drfee = await httpRequest({
                endPoint: `/api/registration/drfee`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            const bo_data = await httpRequest({
                endPoint: `/api/bo/kyc`,
                method: "post",
                instance: "instanceTwo",
                requestBody: backoffice,
            });

            this.setState({ islodding: false })
          

            if (bo_data.status === 200) {
                window.ChangePageNo(18) //dev-trupti on 12-11-2024
            }
        } catch (error) {
            window.ChangePageNo(18) //dev-trupti on 12-11-2024
        }
    }
  render() {
    const { classes } = this.props;
    return (
      <div>
      {this.state.qrpage === 1 &&
        <Grid className={classes.page2card_1}>
            <Typography className={classes.card_tit}>Scan QR for Signature</Typography>

            <Grid style={{ display: 'flex', justifyContent: 'center', marginTop: '20%' }}>

                <div>
                    <QRCode value={this.state.qrlink} logoImage={bull} />,
                </div>

            </Grid>
            <div>

                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20%' }}>

                    <Button fullWidth className={classes.joinDiplomtsBtn}

                        onClick={() => this.setState({ inner_pageno: 7, qrpage: 0 })}
                    >Cancel

                    </Button>


                </Grid>
            </div>
        </Grid>
    }
    {this.state.qrpage === 0 &&
        <Grid className={classes.page2card_1} style={{ marginLeft: window.innerWidth<600 ? '0%':'5%' }} >
            <Typography className={classes.card_tit}>Signature</Typography>




            <Grid style={{ marginTop: '5%' }}>
                <SignatureCanvas penColor='black'

                    velocityFilterWeight='0.1'


                    imageType="image/jpeg"
                    backgroundColor='white'
                    ref={(ref) => { this.signref = ref }}
                    canvasProps={{ width: window.innerWidth < 960 ? 325 : 500, height: 300 }} />
            </Grid>
            {/*Qr Add 28/02/24*/}
            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' ,alignItems:'center',marginTop:'2%'}}>

            <WhiteBackgroundCheckbox
                                                    checked={true}
                                                
                                                ></WhiteBackgroundCheckbox>
                                                <Typography style={{ color: '#FFFFFF', fontFamily: 'cardiuma-regular', fontSize: '16px' }}>I am an Indian citizen, For tax purposes I am not resident in any jurisdiction(s) outside India (FATCA/CRS Declaration)</Typography>
                                                </Grid>
                                             
            <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly',marginTop:'2%' }}>




                <Grid className={classes.button_card1}>
                    <Button fullWidth className={classes.joinDiplomtsBtn}
                        style={{ marginTop: '15px', }}
                        onClick={() => this.CreateQR()}
                    >Scan QR

                    </Button>

                </Grid>
                <Grid className={classes.button_card}>
                    <Button fullWidth className={classes.joinDiplomtsBtn}
                        style={{ marginTop: '15px', }}
                        onClick={() => this.signpadclear()}
                    >Clear

                    </Button>

                </Grid>

                <Grid className={classes.button_card}>
                    <Button fullWidth className={classes.joinDiplomtsBtn}
                        style={{ marginTop: '15px' }}
                        onClick={() => this.Continue_sign()}
                    >Continue

                    </Button>

                </Grid>

            </Grid>

        </Grid>}
          <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
 
                </div>
    )
  }
}
export default (withStyles(styles)((signature)));
