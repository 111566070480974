
import { Typography, Grid, useMediaQuery, Box, Divider, TextField, Button, Link } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import logo from '../../ui-assets/adcampone.png'
import logo2 from '../../ui-assets/adcamptwo.svg'
import congBull from '../../ui-assets/new_bull.svg'
import aniGif from '../../ui-assets/Bullbg.gif'

const MobileView = ({ 
    toggle, anime, history, referrer, userInfo, handleChangeUser, error, errorMessage, handleSubmit
}) => {
    return (
        <Grid container sx={{
            background: "#000",
        }}>
            <Grid item>
                <Grid container padding={1} spacing={0.5} sx={{ background: "#0B1420", width: "auto", height: "auto" }}  >
                    <Grid
                        container
                        item
                        xs={12}
                        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', flexWrap: 'nowrap' }}
                    >
                        <img src={logo2} alt='logo' width={70} style={{ margin: "5px 0 0 0px" }} />
                        <img src={logo} alt='logo' width={160} style={{ margin: "20px 0 0 10px" }} />

                        <Grid
                            item
                            sx={{ display: 'flex', margin: '0 0 0 10px', }}
                        >
                            <Typography
                                className='since'
                                sx={{ margin: '0 0 -5px 0', whiteSpace: 'nowrap' }}
                            >
                                Since 1996
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Divider className='divdr' />
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Typography className='malli'>
                            MALIRAM MAKHARIA FINSTOCK PRIVATE LIMITED
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            {toggle ? (
                <>
                    <Grid
                        container
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: 5,
                                position: 'relative',
                            }}
                        >
                            <img src={congBull} alt='congBull' style={{ position: 'relative' }} />
                            {anime && <img
                                src={aniGif} alt='congBull'
                                style={{ position: 'absolute', top: '30px', }}
                            />}
                        </Box>

                        <Grid
                            container
                            justifyContent='center'
                        >
                            <Typography
                                className='weoffrweb'
                            >
                                Thank You Very Much
                            </Typography>
                        </Grid>

                        <Grid
                            item
                        >
                            <Typography
                                className='shemrph'
                                padding={4}
                                textAlign='center'
                            >
                                Our team will connect with you soon for further communication
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{ textAlign: 'center', background: "#0B1420" }}
                        >
                            <Typography
                                className='shemrph'
                                padding={3}
                            >
                                OR
                            </Typography>
                        </Grid>

                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                            padding={2}
                            sx={{ padding: '0 0 30px 0', background: "#0B1420" }}
                        >
                            <Button
                                className='subbtn'
                                onClick={() => history.push(`/signup?referral=${referrer ? referrer : ""}`)}
                            >
                                Sign Up Now
                            </Button>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{ textAlign: 'center', background: "#0B1420" }}
                        >
                            <Link
                                className='explor'
                                href='https://bullforce.co/'
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Explore More
                                <ArrowForwardIosIcon sx={{ fontSize: '18px' }} />
                            </Link>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid
                        item
                        className='bgimg'
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            position: 'relative',
                        }}
                    >
                        <Box
                            className='bgbox'
                        >
                            <Typography
                                className='bgbxtxt'
                            >
                                We are one of the most experienced stock Broking houses of India
                            </Typography>
                        </Box>

                        <Box
                            className='bgbox1'
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginTop: '14px',
                                    position: 'relative',
                                }}
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        height: '1px',
                                        background: 'linear-gradient(90deg, rgba(217, 217, 217, 0) 0%, #F3D45E 100%)',
                                        marginRight: '8px',
                                    }}
                                />
                                <Typography
                                    className='weoffr'
                                >
                                    We Offer
                                </Typography>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        height: '1px',
                                        background: 'linear-gradient(270deg, rgba(217, 217, 217, 0) 0%, #F3D45E 100%)',
                                        marginLeft: '8px',
                                    }}
                                />
                            </Box>

                            <Grid container display='flex'
                                justifyContent='flex-start' padding={0.5} gap={4} ml="5px">
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    className='offrtxt'
                                >
                                    <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                    Stock Broking
                                </Typography>

                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    className='offrtxt'
                                >
                                    <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                    Demat Account
                                </Typography>
                            </Grid>


                            <Grid container gap='10px' padding={0.5} flexDirection='column' ml="5px">
                                <Grid item>
                                    <Typography
                                        sx={{ display: 'flex', alignItems: 'center', }}
                                        className='offrtxt'
                                    >
                                        <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                        Expert Advice By SEBI Registered Advisors
                                    </Typography>
                                </Grid>

                                <Grid item>
                                    <Typography
                                        sx={{ display: 'flex', alignItems: 'center', }}
                                        className='offrtxt'
                                    >
                                        <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                        Wealth Vaults
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid
                        container
                        justifyContent='center'
                        sx={{
                            position: 'relative'
                        }}
                    >
                        <Typography
                            className='shemrph'
                            padding={0.5}
                            textAlign='center'
                        >
                            Connect with us
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        padding={1}
                    >
                        <Grid item textAlign="center">
                            <TextField
                                className="textfld"

                                id="outlined-basic"
                                label="Email ID / Phone Number"
                                variant="outlined"

                                InputLabelProps={{
                                    sx: {
                                        color: '#fff',
                                        fontSize: '16px',
                                        fontFamily: 'cardiuma-regular',
                                        fontWeight: 400,
                                        // padding: 1
                                    },
                                }}

                                value={userInfo}
                                onChange={(e) => handleChangeUser(e)}
                            />

                            {error && <Typography
                                sx={{ fontFamily: 'cardiuma-regular', fontSize: '14px', fontFamily: '400', color: 'red', padding: '10px' }}
                            >{errorMessage}</Typography>}
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        padding={error ? 0 : 2}
                    >
                        <Button
                            className='subbtn'
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{ textAlign: 'center' }}
                    >
                        <Typography
                            className='shemrph'
                            padding={1}
                        >
                            OR
                        </Typography>
                    </Grid>

                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        padding={3}
                        sx={{ padding: '0 0 100px 0' }}
                    >
                        <Button
                            className='subbtn'
                            onClick={() => history.push(`/signup?referral=${referrer ? referrer : ""}`)}
                        >
                            Sign Up Now
                        </Button>
                    </Grid>
                </>
            )}
        </Grid>
    )
}

export default MobileView