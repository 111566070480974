import React, { Component } from 'react'
import { FileUploader } from "react-drag-drop-files";

import { withStyles, } from "@mui/styles";

import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import Secure from '../../../ui-assets/Secure.svg'
import { Close } from '@mui/icons-material';
import step7mp3 from '../../../ui-assets/step_digilocker.mp3'
import step7_1mp3 from '../../../ui-assets/step_digilocker1.mp3'
import {
    Typography, Grid, Button,Modal,Box, Snackbar, SnackbarContent, IconButton, 
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
  

   
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },



   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },
   page2card_2: {
    width: '100%',
    
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',
        padding: '52px 16px',
        marginLeft: '0%',

    }
},
card_tit: {
    color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

    "@media only screen and (max-device-width: 480px)": {
        fontSize: '24px !important',
    },

},
upload_tit:{
    color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
},
upload_tit2:{
    color: '#1B2636 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
},

upload_tit1:{
    color: '#7A7C7F !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',paddingLeft:'5px'
},
})
const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
  
class documentuploadboth extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            filename1:'',
            filename2:'',
          
            filenamedata1:'',
            filenamedata2:'',
          
            islodding:false,
            audiostep7 : new Audio(step7_1mp3),
            aadharshow:1,
            panshow:1,
            tittext:""

        }



    }
    componentDidMount = async () => {
        let rd1 =  JSON.parse(localStorage.getItem('reg_data') || '{}')
        this.setState({tittext:"Unable to fetch Aadhaar and PAN details  from the Digilocker , please upload the scan copy of  Aadhaar and PAN image with less than 5MB size"})
        if(rd1.aadharupload==="2") //Since Panverify is 2 when it doesnt find aadhar
        {
this.setState({panshow:0})
this.setState({tittext:"Unable to fetch Aadhar details from the Digilocker, please upload the scan copy of  Aadhaar image with less than 5MB size"})
        }

        if(rd1.aadharupload==="1") 
            {
    this.setState({aadharshow:0})
    this.setState({tittext:"Unable to fetch PAN details from the Digilocker, please upload the scan copy of  PAN image with less than 5MB size"})
            }
            
        /*setTimeout(
            function() {
                this.state.audiostep7.play()
            }
            .bind(this),
            1000
        );*/
            
        }
        
    handleChangefile1  = async (file) => {
        let fsize=(file.size / (1024*1024)).toFixed(2);
        console.log(fsize)
        if(fsize>5)
        {
            this.setState({ showerr: true, errorMsg: "Max 5MB allowed", mysnack: snackeror.myerror })
        }
        if(fsize<=5)
        {
        const base64 = await convertBase64(file);
        this.setState({filenamedata1:base64,filename1:file.name})
        }
    };
    handleChangefile2  = async (file) => {
        console.log(file.size)
        let fsize=(file.size / (1024*1024)).toFixed(2);
        console.log(fsize)
        if(fsize>5)
        {
            this.setState({ showerr: true, errorMsg: "Max 5MB allowed", mysnack: snackeror.myerror })
        }
        if(fsize<=5)
        {
        const base64 = await convertBase64(file);
        this.setState({filenamedata2:base64,filename2:file.name})
        }
    };


    closeContactModal() {
        this.setState({ showerr: false })
    }
    UploadDoc = async () => {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let mobileno=rd2.mobileno
let citizen=rd2.citizen
let aadharno=rd2.aadharno
        const {filename1,filename2,filenamedata1,filenamedata2}=this.state
let isok=0
       
if (!filename1.length && isok === 0 && this.state.aadharshow===1) {
            
    isok = 1
   this.setState({ showerr: true, errorMsg: "Select Copy of valid Aadhaar card", mysnack: snackeror.myerror })


}

        if (!filename2.length && isok === 0 && this.state.panshow===1) {
            
            isok = 1
           this.setState({ showerr: true, errorMsg: "Select Copy of valid PAN card", mysnack: snackeror.myerror })


        }


       

        if(isok===0)
        {

            let payload1={
                emailid:emailid,
                mobileno:mobileno,
                type:1,
                data:filenamedata1,
                "aadhar":aadharno.substring(8,12)
            }

            let payload2={
                emailid:emailid,
                mobileno:mobileno,
                type:2,
                data:filenamedata2,
                "aadhar":aadharno.substring(8,12)
            }
            

          this.setState({islodding:true})  

          if(this.state.aadharshow===1 && this.state.panshow===0)  
          {

            const fileu2 = await httpRequest({
                endPoint: `/api/document/upload`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload1,
            });
        
            if (fileu2.status === 200) {
                window.ChangePageNo (11.1)
            }

          }
          if(this.state.aadharshow===0 && this.state.panshow===1)  
            {
  
              const fileu2 = await httpRequest({
                  endPoint: `/api/document/upload`,
                  method: "post",
                  instance: "instanceTwo",
                  requestBody: payload2,
              });
          
              if (fileu2.status === 200) {
                  window.ChangePageNo (11.1)
              }
  
            }

        if(this.state.aadharshow===1 && this.state.panshow===1)  
        {
try {
    
    const fileu2 = await httpRequest({
        endPoint: `/api/document/upload`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload1,
    });

    if (fileu2.status === 200) {

        try {
            const fileu1 = await httpRequest({
                endPoint: `/api/document/upload`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload2,
            });
            if (fileu1.status === 200) {
        
                this.setState({islodding:false})  
                if(citizen===0)
                {
                window.ChangePageNo (11.1)
                }
        
               if(citizen===1)
                {
                window.ChangePageNo (11.1)
                }    
        
        
        
        
            }
        } catch (error) {
            
        }
    }

} catch (error) {
    
}
        }



        }

    }
  render() {
    const { classes } = this.props;
    return (
      <div>    <Grid className={classes.page2card_2}  >
      <Typography className={classes.card_tit}>Upload documents</Typography>

      <Grid style={{marginTop:'5%',width:'100%'}}>
      <Typography className={classes.upload_tit}>{this.state.tittext}</Typography>
   </Grid>
   {/* to show aadhara upoad if not select in digilocker */}
    {this.state.aadharshow===1 &&
   <Grid style={{marginTop:'5%',width:'100%'}}>
      <Typography className={classes.upload_tit}>Copy of valid Aadhaar card</Typography>
      <FileUploader
          style={{marginTop:'5px'}}
          multiple={false}
          
          hoverTitle="rAI"
          label="Upload or drop a file right here"

          //   onChange={(e) => this.handleChange(e)}
          handleChange={this.handleChangefile1}
          name="file"
          
          children={
              <Grid style={{ padding: '15px',  width: '100%', background: '#1B2636', borderRadius: '8px', border: '1px dashed #999999' }}>
                 
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                      <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
                     
                  </Grid>
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.filename}</Typography>
                     
                  </Grid>
              </Grid>
          }
      />

      <Grid style={{width:'100%',display:'flex',marginTop:'2px'}}>
          <Grid style={{width:'75%'}}>
          <Typography className={classes.upload_tit1}>{this.state.filename1}</Typography>
          </Grid>
          <Grid style={{width:'30%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
              <img src={Secure} alt="Secure"></img>
          <Typography className={classes.upload_tit1}>Secure</Typography>
              </Grid>
      </Grid>
          </Grid>   
  }
  {/* to show pan upoad if not select in digilocker */}
  {this.state.panshow===1 &&
          <Grid style={{marginTop:'5%',width:'100%'}}>
      <Typography className={classes.upload_tit}>Copy of valid PAN card</Typography>
      <FileUploader
          style={{marginTop:'5px'}}
          multiple={false}
          
          hoverTitle="rAI"
          label="Upload or drop a file right here"

          //   onChange={(e) => this.handleChange(e)}
          handleChange={this.handleChangefile2}
          name="file"
          
          children={
              <Grid style={{ padding: '15px',  width: '100%', background: '#1B2636', borderRadius: '8px', border: '1px dashed #999999' }}>
                 
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                      <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
                     
                  </Grid>
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.filename}</Typography>
                     
                  </Grid>
              </Grid>
          }
      />

      <Grid style={{width:'100%',display:'flex',marginTop:'2px'}}>
          <Grid style={{width:'75%'}}>
          <Typography className={classes.upload_tit1}>{this.state.filename2}</Typography>
          </Grid>
          <Grid style={{width:'30%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
              <img src={Secure} alt="Secure"></img>
          <Typography className={classes.upload_tit1}>Secure</Typography>
              </Grid>
      </Grid>
          </Grid>  
  }   
          <Grid style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>


        

<Grid style={{width:'100%'}}>
<Button fullWidth className={classes.joinDiplomtsBtn}
          style={{ marginTop: '15px'}}
          onClick={() => this.UploadDoc()}
      >Continue

      </Button>

</Grid>
          </Grid>
          </Grid>
          <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

</Modal>
<Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
</div>
    )
  }
}
export default (withStyles(styles)((documentuploadboth)));