import React, { Component } from 'react'
import { FileUploader } from "react-drag-drop-files";

import { withStyles, } from "@mui/styles";

import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import logo from '../../../ui-assets/icon.png'
import bull from '../../../ui-assets/bull.svg'
import Secure from '../../../ui-assets/Secure.svg'
import countries from "../../../ui-utils/countries.json"
import { Close } from '@mui/icons-material';
import {
    Typography, Grid, Button,Modal,Box, Snackbar, SnackbarContent, IconButton,RadioGroup,FormControlLabel,Radio, 
    FormControl,InputLabel,Select,MenuItem,FormHelperText,TextField,Checkbox
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
      
   
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#F74542",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#F74542'
        }
    },
    checked: { color: "#F74542", }
}))(Checkbox);
const styles = theme => ({
  

   
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },

   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },



   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },
   page2card_2: {
    width: '100%',
    
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',
        padding: '2px 16px',
        marginLeft: '0%',

    }
},
card_tit: {
    color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

    "@media only screen and (max-device-width: 480px)": {
        fontSize: '24px !important',
    },

},
upload_tit:{
    color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
},

upload_tit4:{
    color: '#1B2636 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
},

upload_tit1:{
    color: '#7A7C7F !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',paddingLeft:'5px'
},
//added 21/03/24
joinDiplomtsBtn_disable: {
    background: "#999999 !important",
    padding: "12px 45px !important",
    borderRadius: "20px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-M !important"
},
cong_card: {
    position: 'absolute',
    top: '55%',
    left: '0%',
    transform: 'translate(-10%, -50%)',
    width: '100% !important',
    height: 'auto',
   
display:'grid',
justifyContent:'center',

    outline: 'none',
    
    "@media only screen and (max-device-width: 480px)": {
        top: '15%',
        left: '0%',
        transform: 'none',
        width: '100%',
    }
},
cong_card1: {
    width:'100%',
    "@media only screen and (max-device-width: 480px)": {
        width:'100%',
    }
},
icon: {
    fill: 'white',
 },
 qtxt: {
    color: '#999999 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
},

})
const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
  
class nri_upload extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            countryname:'',
            panfilename:'',
            pandata:'',

            Identityfilename:'',
            Identitydata:'',
            

            bankfilename:'',
            bankdata:'',
            communicationfilename:'',
            communicationdata:'', 
            islodding:false,
        
            tandcond:true,//added 21/03/24
            condbut:false,
            ntype:'',//added on 20/08/24
            citizen:'',//added on 24/08/24 To validate NRI. 
            qlist:[],
            qdisp:'',
            add_type:''
        }



    }
    componentDidMount = async () => {
        let rd1 =  JSON.parse(localStorage.getItem('reg_data') || '{}')
        if(rd1.nre===true)
        {
          this.setState({  ntype:"NRI"})
        }
        if(rd1.nro===true)
            {
                this.setState({  ntype:"NRO"})
            }
  
            this.setState({  ntype:"NRI"})
      }
    

    
    
      handlePanfile  = async (file) => {
        let fsize=(file.size / (1024*1024)).toFixed(2);
        let isok=0
   
       
        if(  file.type !== "application/pdf" && file.type !== "image/png" && file.type !== "image/jpeg" && file.type!=="image/gif") 
        {
            isok=1
            this.setState({ showerr: true, errorMsg: "Invalid File Format (Only jpg,png,gif,pdf supported)", mysnack: snackeror.myerror })
        }
    

        if(fsize>2)
        {
            isok=1
            this.setState({ showerr: true, errorMsg: "Max 2MB allowed", mysnack: snackeror.myerror })
        }
        if(fsize<2 &&  isok===0)
        {
        const base64 = await convertBase64(file);
        this.setState({pandata:base64,panfilename:file.name})
        }
    };
    handleIdentity  = async (file) => {
        let fsize=(file.size / (1024*1024)).toFixed(2);
        let isok=0
   
        if( file.type !== "application/pdf"  && file.type !== "image/png" && file.type !== "image/jpeg" && file.type!=="image/gif") 
        {
            isok=1
            this.setState({ showerr: true, errorMsg: "Invalid File Format (Only jpg,png,gif,pdf supported)", mysnack: snackeror.myerror })
        }
    

        if(fsize>2)
        {
            isok=1
            this.setState({ showerr: true, errorMsg: "Max 2MB allowed", mysnack: snackeror.myerror })
        }
        if(fsize<2 &&  isok===0)
        {
        const base64 = await convertBase64(file);
        this.setState({Identitydata:base64,Identityfilename:file.name})
        }
    };

handleBank  = async (file) => {

    let fsize=(file.size / (1024*1024)).toFixed(2);
    let isok=0

    if(file.type !== "application/pdf"  && file.type !== "image/png" && file.type !== "image/jpeg" && file.type!=="image/gif") 
    {
        isok=1
        this.setState({ showerr: true, errorMsg: "Invalid File Format (Only jpg,png,gif,pdf supported)", mysnack: snackeror.myerror })
    }


    if(fsize>10)
    {
        isok=1
        this.setState({ showerr: true, errorMsg: "Max 2MB allowed", mysnack: snackeror.myerror })
    }
    if(fsize<10 &&  isok===0)
    {
        const base64 = await convertBase64(file);
        this.setState({bankdata:base64,bankfilename:file.name})
    }
    };
    handlecommunication   = async (file) => {
        let fsize=(file.size / (1024*1024)).toFixed(2);
        let isok=0
    
        if(file.type !== "application/pdf"  && file.type !== "image/png" && file.type !== "image/jpeg" && file.type!=="image/gif") 
        {
            isok=1
            this.setState({ showerr: true, errorMsg: "Invalid File Format (Only jpg,png,gif,pdf supported)", mysnack: snackeror.myerror })
        }
    
    
        if(fsize>2)
        {
            isok=1
            this.setState({ showerr: true, errorMsg: "Max 2MB allowed", mysnack: snackeror.myerror })
        }
        if(fsize<2 &&  isok===0)
        {
            const base64 = await convertBase64(file);
            this.setState({communicationdata :base64,communicationfilename:file.name})
        }
        };
    closeContactModal() {
        this.setState({ showerr: false })
    }
    GetAddressOptions = async (e) => {
        let add_type=e.target.value
        console.log(add_type)

        let payload={
            ntype:'NRI',//this.state.ntype,
            add_type:add_type
        }
        try {
            const data = await httpRequest({
                endPoint: `/api/nri/commdetails`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            
            if(data.status===200)
            {
 
                

                const qlist1 = data.data.map(data => {
                    return { name: data.question, value:data.question, disp: data.description
                    };
                  });

                  
                  this.setState({qlist:qlist1})
            }
        } catch (error) {
            
        }
    }
    UploadDoc = async () => {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let mobileno=rd2.mobileno
let id=rd2.id

        const {countryname,panfilename,Identityfilename,bankfilename,pandata,Identitydata,bankdata,

communicationdata,communicationfilename,ntype,add_type


        }=this.state
let isok=0

if (!countryname.length && isok === 0) {
            
    isok = 1
   this.setState({ errtype:6, errorMsg: "Select the Country name" })


}

        if (!panfilename.length && isok === 0) {
            
            isok = 1
           this.setState({ showerr: true, errorMsg: "Upload Copy of valid passport with visa", mysnack: snackeror.myerror })


        }

         if (!Identityfilename.length && isok === 0) {
          
             isok = 1
            this.setState({ showerr: true, errorMsg: "Upload Proof of Identity", mysnack: snackeror.myerror })


         }

         if (!bankfilename.length && isok === 0) {
          
            isok = 1
           this.setState({ showerr: true, errorMsg: "Upload 6 Month Bank Statment", mysnack: snackeror.myerror })


        }

        if (!communicationfilename.length && isok === 0) {
          
            isok = 1
           this.setState({ showerr: true, errorMsg: "Upload Proof of communication address", mysnack: snackeror.myerror })


        }//added on 18/09/24 Validation for proof selection. 
        if (!ntype.length && isok === 0) {
          
            isok = 1
           this.setState({ showerr: true, errorMsg: "Select the  Proof of communication address", mysnack: snackeror.myerror })


        }
        if (!add_type.length && isok === 0) {
          
            isok = 1
           this.setState({ showerr: true, errorMsg: "Select the  Proof of communication address", mysnack: snackeror.myerror })


        }
        if(isok===0)
        {

            let payload1={
                emailid:emailid,
                mobileno:mobileno,
                type:1,
                data:pandata,
                id:id,
                filename:panfilename //added on 11/09/24 to send filename
            }

            let payload2={
                emailid:emailid,
                mobileno:mobileno,
                type:2,
                data:Identitydata,
                id:id,
                filename:Identityfilename//added on 11/09/24 to send filename
            }
            

            let payload3={
                emailid:emailid,
                mobileno:mobileno,
                type:3,
                data:bankdata,
                id:id,
                filename:bankfilename//added on 11/09/24 to send filename
            }
            let payload4={
                emailid:emailid,
                mobileno:mobileno,
                type:4,
                data:communicationdata,
                id:id,
                ctype:ntype,
                cdisp:add_type,
                countryname:countryname,
                filename:communicationfilename//added on 11/09/24 to send filename

            }
            
         

this.setState({islodding:true})

try {
    const panfileup = await httpRequest({
               endPoint: `/api/nri/upload`,
                 method: "post",
                 instance: "instanceTwo",
                 requestBody: payload1,
             });
             this.setState({islodding:false})
             if(panfileup.status!==200)
             {
                this.setState({ showerr: true, errorMsg: "Something went wrong try after some time. ", mysnack: snackeror.myerror })
             }
             if(panfileup.status===200)
                {
                    this.setState({islodding:true})
                   
try {
    const Identitydfileup = await httpRequest({
        endPoint: `/api/nri/upload`,
          method: "post",
          instance: "instanceTwo",
          requestBody: payload2,
      });
      this.setState({islodding:false})
      if(Identitydfileup.status!==200)
        {
           this.setState({ showerr: true, errorMsg: "Something went wrong try after some time. ", mysnack: snackeror.myerror })
        }
        if(Identitydfileup.status===200)
            {
                this.setState({islodding:true})
                const bankfileup = await httpRequest({
                    endPoint: `/api/nri/upload`,
                      method: "post",
                      instance: "instanceTwo",
                      requestBody: payload3,
                  });
                  this.setState({islodding:false})
                  if(bankfileup.status!==200)
                    {
                       this.setState({ showerr: true, errorMsg: "Something went wrong try after some time. ", mysnack: snackeror.myerror })
                    }
                    if(bankfileup.status===200)
                        {
                           
                            const commfileup = await httpRequest({
                                endPoint: `/api/nri/upload`,
                                  method: "post",
                                  instance: "instanceTwo",
                                  requestBody: payload4,
                              });
                              if(commfileup.status!==200)
                                {
                                   this.setState({ showerr: true, errorMsg: "Something went wrong try after some time. ", mysnack: snackeror.myerror })
                                }
                                if(commfileup.status===200)
                                    {
                                        window.ChangePageNo (12)
                                    }
                        }
            }
} catch (error) {
    
}


                }

} catch (error) {
    
}


        

    }

    }

    authorise(){
        this.setState({ tandcond: !this.state.tandcond ,condbut: !this.state.condbut})
        }

        handelAns(e){
            console.log(e.target.value)
this.setState({add_type:e.target.value})
            let sname =""
             this.state.qlist.map(data => {
                 if(data.name===e.target.value)sname=data.disp
              });
this.setState({qdisp:sname,errtype:16})
              
            }
  render() {
    const { classes } = this.props;

    
    const countriesData = countries.map(data => {
        return { label: data.name, value: data.dial_code };
      });
    return (
        <Grid style={{width:'100%',display:'flex',height:'auto'}}>
            <Grid style={{width:'10%',display:'flex'}}>

            </Grid>
            <Grid style={{width:'80%',background:'#1B2636',padding:'2%'}}>
                <img src={logo}></img>
            <Typography className={classes.card_tit} style={{marginTop:'2%'}}>Upload Required Documents</Typography>
            
            {/* countery */}
            <Grid style={{width:'100%',display:'flex',alignItems:'center',marginTop:'2%'}}>
            <Grid style={{width:'30%',display:'flex'}}>
            <FormControl variant="standard" sx={{
              minWidth: '100%', '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
          }}>
              <InputLabel id="demo-simple-select-standard-label"

                  style={{ color: this.state.errtype === 6 ? "#F74542" : '#BCD171', fontFamily: 'cardiuma-regular', fontSize: '12px' }}



              >Country</InputLabel>
              <Select
                  id="demo-simple-select-standard-label"
                
                  values={this.state.countryname}
                  variant="standard"
                  sx={{
                  
                  
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  }}
                  fullWidth
                  options={countriesData}
                  style={{ color: '#ffffff' }}

                  onChange={e => {
                      this.setState({ errtype: 0, errorMsg: "", countryname: e.target.value });


                  }}
              >
 {countries.map(({ name}, index) => (
            <MenuItem key={index} value={name} style={{fontFamily: 'cardiuma-regular !important', fontSize: '14px'}}>
              {name}
            </MenuItem>
          ))}
                 
              </Select>
              {this.state.errtype === 6 &&
                  <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
              }

          </FormControl>

                </Grid>      
            <Grid style={{width:'30%',display:'flex'}}></Grid>
            <Grid style={{width:'30%',display:'flex'}}></Grid>
            </Grid>
            {/* 1ST  */}
            <Grid style={{width:'100%',display:'flex',alignItems:'center',marginTop:'2%'}}>
            <Grid style={{width:'30%',display:'flex'}}>
            <FormControl style={{ width: '100%' }} variant="standard">
            <TextField size="small" variant="standard"
      fullWidth
      type='number'
      disabled="true"
      sx={{
          '& .MuiInput-input': {
              '-moz-appearance': 'textfield',
              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
              }},
          '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
          '& .MuiInput-underline:hover:before': { borderBottomColor: '#999999' },
         '& .MuiInput-underline:after': { borderBottomColor: '#999999' },
     }}
     InputLabelProps={{
         style: { color: this.state.errtype === 7 ? "#999999" : '#999999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
      }}
      label='PAN Card *'

     
      InputProps={{
          classes: { input: classes.input },
          style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
      }}
     
  ></TextField>
  </FormControl>
            </Grid>
            <Grid style={{width:'30%',display:'flex',marginLeft:'3%'}}>
            <FileUploader
          style={{}}
          multiple={false}
          maxSize={'2mb'}
          hoverTitle="rAI"
          label="Upload or drop a file right here"

          //   onChange={(e) => this.handleChange(e)}
          handleChange={this.handlePanfile}
          name="file"
          
          children={
              <Grid style={{ padding: '12px',  width: '100%', background: '#1B2636', borderRadius: '4px', border: '1px dashed #999999' }}>
                 
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                      <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
                     
                  </Grid>
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      
                      
                     
                  </Grid>
              </Grid>
          }
      />
            </Grid>
            <Grid style={{width:'30%',display:'flex'}}>
            <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.panfilename}</Typography>
            </Grid>
            </Grid>
   {/* 2nd  */}
   <Grid style={{width:'100%',display:'flex',alignItems:'center',marginTop:'2%'}}>
   
            <Grid style={{width:'30%'}}>
            <Typography className={classes.upload_tit}>Proof of Identity</Typography>
            <FormControl style={{ width: '100%' }} variant="standard">
           <TextField size="small" variant="standard"
     fullWidth
     type='number'
     disabled="true"
     sx={{
         '& .MuiInput-input': {
             '-moz-appearance': 'textfield',
             '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
               '-webkit-appearance': 'none',
             }},
         '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
         '& .MuiInput-underline:hover:before': { borderBottomColor: '#999999' },
         '& .MuiInput-underline:after': { borderBottomColor: '#999999' },
     }}
     InputLabelProps={{
         style: { color: this.state.errtype === 7 ? "#999999" : '#999999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
     }}
     label='Copy of valid Indian Passport (First & Last Page)'

     
     InputProps={{

         classes: { input: classes.input },
         style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
     }}
     
 ></TextField>
 </FormControl>
            </Grid>
            <Grid style={{width:'30%',display:'flex',marginLeft:'3%'}}>
            <FileUploader
          style={{}}
          multiple={false}
          maxSize={'2mb'}
          hoverTitle="rAI"
          label="Upload or drop a file right here"

          //   onChange={(e) => this.handleChange(e)}
          handleChange={this.handleIdentity}
          name="file"
          
          children={
              <Grid style={{ padding: '12px',  width: '100%', background: '#1B2636', borderRadius: '4px', border: '1px dashed #999999' }}>
                 
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                      <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
                     
                  </Grid>
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      
                    
                     
                  </Grid>
              </Grid>
          }
      />

            </Grid>
            <Grid style={{width:'30%',display:'flex'}}>
            <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.Identityfilename}</Typography>
</Grid>
            </Grid>
            <Grid style={{width:'100%',display:'flex',alignItems:'center',marginTop:'2%'}}>
   
   <Grid style={{width:'30%'}}>
   <Typography className={classes.upload_tit}>Bank Statment</Typography>
   <FormControl style={{ width: '100%' }} variant="standard">
  <TextField size="small" variant="standard"
fullWidth
type='number'
disabled="true"
sx={{
'& .MuiInput-input': {
    '-moz-appearance': 'textfield',
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
    }},
'& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
'& .MuiInput-underline:hover:before': { borderBottomColor: '#999999' },
'& .MuiInput-underline:after': { borderBottomColor: '#999999' },
}}
InputLabelProps={{
style: { color: this.state.errtype === 7 ? "#999999" : '#999999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
}}
label='6 Month Bank Statment'


InputProps={{

classes: { input: classes.input },
style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
}}

></TextField>
</FormControl>
   </Grid>
   <Grid style={{width:'30%',display:'flex',marginLeft:'3%'}}>
   <FileUploader
 style={{}}
 multiple={false}
 maxSize={'2mb'}
 hoverTitle="rAI"
 label="Upload or drop a file right here"

 //   onChange={(e) => this.handleChange(e)}
 handleChange={this.handleBank}
 name="file"
 
 children={
     <Grid style={{ padding: '12px',  width: '100%', background: '#1B2636', borderRadius: '4px', border: '1px dashed #999999' }}>
        
         <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
             <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
             <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
            
         </Grid>
         
     </Grid>
 }
/>
   </Grid>
   <Grid style={{ width: '30%',display:'flex',justifyContent:'center' }}>
             
          
         <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.bankfilename}</Typography>
         </Grid>
   </Grid>
   {/* 4th  */}
   <Grid style={{width:'100%',display:'flex',alignItems:'center',marginTop:'2%'}}>
   <Typography className={classes.upload_tit}>Proof of communication address</Typography>
    </Grid>
   <Grid style={{width:'100%',display:'flex',alignItems:'center'}}>
   
            <Grid style={{width:'30%'}}>
          
            <FormControl variant="standard" sx={{
              minWidth: '100%', '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
          }}>
             
              <Select
               
                  variant="standard"
                  sx={{
                  
                  
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  }}
                  fullWidth
                  inputProps={{
                    classes: {
                     
                        icon: classes.icon,
                    },
                }}
                  style={{ color: '#999999' }}

                  onChange={e => {
                     
                    this.GetAddressOptions(e)

                  }}
              >
 
            <MenuItem key='1' value="Overseas" style={{fontFamily: 'cardiuma-regular !important', fontSize: '14px'}}>
             Overseas 
            </MenuItem>
            <MenuItem key='2'value="Indian " style={{fontFamily: 'cardiuma-regular !important', fontSize: '14px'}}>
            Indian  
            </MenuItem>
 
                 
              </Select>
            
          </FormControl>
            </Grid>
            <Grid style={{width:'30%',marginLeft:'3%'}}>
            <FormControl variant="standard" sx={{
              minWidth: '100%', '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
          }}>
             
              <Select
                  sx={{
                  
                  
                    '.MuiSvgIcon-root ': {
                      fill: "white !important",
                    }
                  }}
                  variant="standard"
                
                  fullWidth
                
                  style={{ color: '#999999' }}

                  onChange={e => {
                    this.handelAns(e)


                  }}
              >
 
 {this.state.qlist.map(({ name,disp}, index) => (
             <MenuItem key={index} value={name} name={disp} style={{fontFamily: 'cardiuma-regular !important', fontSize: '14px'}}>
               {name}
             </MenuItem>
           ))}
 
                 
              </Select>
              

          </FormControl>
        
            </Grid>
            <Grid style={{width:'30%',display:'flex',marginLeft:'3%'}}>
            <FileUploader
          style={{}}
          multiple={false}
          maxSize={'2mb'}
          hoverTitle="rAI"
          label="Upload or drop a file right here"

          //   onChange={(e) => this.handleChange(e)}
          handleChange={this.handlecommunication}
          name="file"
          
          children={
              <Grid style={{ padding: '12px',  width: '100%', background: '#1B2636', borderRadius: '4px', border: '1px dashed #999999' }}>
                 
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                      <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
                     
                  </Grid>
                
              </Grid>
          }
      />
            </Grid>
           
            </Grid>
            {/* 4th dis */}
            <Grid style={{width:'100%',display:'flex',alignItems:'center'}}>
   
   <Grid style={{width:'30%'}}>
 
   
   </Grid>
   <Grid style={{width:'30%',marginLeft:'3%'}}>
   
 {this.state.errtype === 16 &&
         <Typography className={classes.qtxt} id="component-error-text">{this.state.qdisp}</Typography>
     }
   </Grid>
   <Grid style={{width:'30%',display:'flex',marginLeft:'3%'}}>
   <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.communicationfilename}</Typography>
   </Grid>
   </Grid>
   <Grid style={{width:'100%',display:'flex',alignItems:'center',marginTop:'2%'}}>
   
   <Grid style={{width:'30%'}}>
 
   
   </Grid>
   <Grid style={{width:'30%'}}>
 
   
   </Grid>
   <Grid style={{width:'30%',display:'flex',marginLeft:'3%'}}>
   <Button fullWidth 
 disabled={this.state.condbut}
 className={this.state.condbut?classes.joinDiplomtsBtn_disable: classes.joinDiplomtsBtn}

           style={{ marginTop: '15px'}}
           onClick={() => this.UploadDoc()}
       >Continue

       </Button>
   </Grid>
   </Grid>
            </Grid>
            <Grid style={{width:'10%',display:'flex'}}>

            </Grid>
            


          <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

</Modal>
 <Snackbar
                     anchorOrigin={{
                         vertical: "bottom",
                         horizontal: "right"
                     }}
                     open={this.state.showerr}
                     autoHideDuration={3000}
                     onClose={() => this.closeContactModal()}
                 >

                     <SnackbarContent
                         style={this.state.mysnack}
                         message={

                             <span style={{
                                 display: "flex",
                                 alignItems: "center",
                                 color: "#333333",
                                 fontSize: "12px",
                                 fontFamily: "cardiuma-M !important"
                             }}>

                                 {this.state.errorMsg}

                             </span>
                         }
                         action={
                             <React.Fragment>

                                 <IconButton
                                     size="small"
                                     aria-label="close"
                                     color="inherit"
                                     onClick={() => { this.setState({ showerr: false }) }}
                                 >
                                     <Close fontSize="small" />
                                 </IconButton>
                            </React.Fragment>
                         }
                     >

                     </SnackbarContent>

                 </Snackbar>
                 <Modal
                     sx={{ border: 'none' }}
                     open={this.state.islodding}
                 >

                     <Box
                         style={{
                             position: 'absolute',
                             top: '50%',
                             left: '50%',
                             transform: 'translate(-50%, -50%)',
                             width: '20%',
                             height: '8%',

                             outline: 'none',
                             background: 'transparent',
                             p: 4,
                             borderRadius: '12px',

                             justifyContent: 'center', alignItems: 'center'
                         }}
                     >

                         <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                             <img src={bull} alt={bull} width='50%' height='50%' />
                         </Grid>
                         <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                             <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                         </Grid>
                     </Box>

                 </Modal> 

</Grid>
    )
  }
}
export default (withStyles(styles)((nri_upload)));