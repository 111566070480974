import axios from "axios";
import backendHosts from './apiConfig'






let axiosInstances = {
  instanceOne: axios.create({

    baseURL: backendHosts.CRM,

    headers: {
      "Accept": "*/*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "accept": "application/json",
     
    }

  }),
  instanceTwo: axios.create({

    baseURL: backendHosts.REG,

    headers: {
      "Accept": "*/*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "accept": "application/json",
    
    }

  }),

  instanceThree: axios.create({

    baseURL: backendHosts.PAYMENT,
    headers: {
      "Accept": "*/*",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "accept": "application/json",
    
    }

  }),

};

export const httpRequest = async ({
  method = "post",
  endPoint,
  params = {},
  requestBody = {},
  instance = "instanceOne",
  
  headers = {}

}) => {


  var headerConfig = {
    headers
  };

  //21500

  //params = JSON.stringify(params)


  requestBody = JSON.stringify(requestBody)


  /* const myKey = 'params';
   params = {
     [myKey]: params
   };
 
   const myKey1 = 'params';
   requestBody = {
     [myKey1]: requestBody
   };*/


  var response;
  //console.log("pay load ", requestBody)
  response = "ERROR"
  try {
    switch (method) {
      case "post":
        response = await axiosInstances[instance].post(
          endPoint,
          requestBody,
          headerConfig
        );
        break;
      default:



        response = await axiosInstances[instance].get(
          endPoint,
          params,
          headerConfig,


        );
    }


    

    let dedata = response.data;


    return dedata;
  } catch (error) {

    return response.data;
  }

  return response


}
