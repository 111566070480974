import React, { Component } from 'react'
import { withStyles } from '@mui/styles'
import { Typography, Grid, Tab, Tabs, styled, Slider, OutlinedInput, InputAdornment, Box, Button, Avatar } from '@mui/material'
import { PieChart } from '@mui/x-charts/PieChart';
import { LineChart } from '@mui/x-charts/LineChart';
import SquareIcon from '@mui/icons-material/Square';
import styles from "../calculators/calculators.module.css"
import SIP from "../../ui-assets/SIP.png"
import Lumpsum from "../../ui-assets/Lumpsum.png"
import StepupSIPCalculator from "../../ui-assets/StepUpSIPCalculator.png"
import Equities from "../../ui-assets/EquitiesF&O.png"
import Currency from "../../ui-assets/Currency.png"


class calculatorLandingPage extends Component {
    constructor(props) {
        super(props)


        this.state = {
            tabvalue: 0,
            list : [
                { name : "SIP" , image : SIP, url : "/sip-calculator"},
                { name : "Lumpsum" , image : Lumpsum, url : "/lumpsum-calculator"},
                { name : "Step up SIP Calculator" , image : StepupSIPCalculator, url : "/stepup-sip-calculator"},
                { name : "Equities - F&O" , image : Equities, url : "/brokerage?type=EquitiesFO"},
                { name : "Currency" , image : Currency, url : "/brokerage?type=Currency"}
            ]
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#1B2636"
    }

    
    render() {
        const { classes } = this.props;

        return (
            <div style={{ flexDirection: "column", display: "flex", alignItems: "center", justifyContent: "center" , padding : "20px"}}>
                <Typography className={styles.calculatorText}>Calculators</Typography>
                {this.state.list.map((ele,index)=>{
                    return(
                        <Grid onClick = {()=>window.open(ele.url)} className={styles.calculatorList}>
                            <Avatar src={ele.image} className={styles.avatarLogo}/>
                            <Typography className={styles.calculatorName}>
                                {ele.name}
                            </Typography>
                        </Grid>
                    )
                })}
                
            </div >
        )
    }
}
export default calculatorLandingPage