import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { httpRequest } from '../../../ui-utils/api'
import {signup_funcation} from './signup_funcation'
import { inputLabelClasses } from "@mui/material/InputLabel";
import step4mp3 from '../../../ui-assets/step_4.mp3'
import * as CryptoJS from 'crypto-js';
import {
    Typography, Grid, Button,Modal,Box,FormHelperText,TextField,FormControl
} from '@mui/material'
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};

const styles = theme => ({
    page1card: {
        width: '100%',
     
     
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '90px 16px',

        }
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
  
})
 class pan extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
        
            emailid:'',
            audiostep4 : new Audio(step4mp3),
           
        islodding:false,
            aadharnobuttonst: true,
        }



    }
    componentDidMount = async () => {
//added on 23/09/24 to pay aution if aadhar is yes
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)

let profile_img=rd2.profile_img.toString()

if(profile_img.toString().length>0)
{
        setTimeout(
            function() {
                this.state.audiostep4.play()
            }
            .bind(this),
            1000
        );
    }           
        }
        
    ContinuePan = async () => {
        const { panno } = this.state
        let isok = 0

        if (!panno.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 4, errorMsg: "Enter the PAN Number" })


        }





        if (panno.length !== 10 && isok === 0) {

            isok = 1
            this.setState({ errtype: 4, errorMsg: "Enter valid PAN Number" })


        }




        if (isok === 0) {

            const {  panno  } = this.state
            let rd1 = localStorage.getItem('reg_data') || '{}'
            let rd2 = JSON.parse(rd1)
            let emailid=rd2.emailid
    let mobileno=rd2.mobileno
    let client_id=rd2.client_id
    let citizen=rd2.citizen
let dob=rd2.dob


let fullname=rd2.fullname
            let payload = {

                "panno": CryptoJS.AES.encrypt( panno.toString().toUpperCase(), "96358214256984120").toString(),
                "fullname": fullname,
                "dob": dob,
                "mobileno": mobileno,
                "emailid": emailid
            }
            this.setState({ islodding: true })
            //Add two redirect pan verification for NRI flow. 
            let otp
            try {
                if(citizen===0)
                {
                 otp = await httpRequest({
                    endPoint: `/api/verfy/panVerfy`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
            }else
            
                {
                 otp = await httpRequest({
                    endPoint: `/api/nri/panVerfy`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
            }
            
                this.setState({ islodding: false })
                if (otp === "API_ERROR") {
                    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                }
                if (otp.status === 201 && otp.panverifed === false) {
                    this.setState({ panverify_st: 0, errtype: 4, errorMsg: otp.message })
                }
                if (otp.status === 200 && otp.panverifed === false) {
                    this.setState({ panverify_st: 0 })
                }

                if (otp.status === 200 && otp.panverifed === true) {

                    signup_funcation(rd1,"panverfy",1)
                  /*  this.setState({

                        pageno: 4, panverify_st: 1, backimg_style: 1, panverfy: 1

                    })*/


                    let obj={}
                    let maindata=JSON.parse( rd1)
                    for (var key in maindata) {
                
                let f=0
                if(key==="panverfy")
                {
                    obj[key]=1
                    f=1
                }
                if(key==="panno")
                {
                    obj[key]=panno
                    f=1
                }
               if(dob.length===0)
               {
                if(key==="fullname")
                    {
                        obj[key]=otp.name
                        f=1
                    }
               }
                
                if(f===0)
                 {
                        obj[key]=maindata[key]
                }
                
                    }
                
                    localStorage.removeItem('reg_data')
                    localStorage.setItem('reg_data', JSON.stringify(obj))
                  
    

                    window.ChangePageNo (4)

                } else {

                }



            } catch (error) {

                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

            }


        }

    }
    GetPanNoNumber(e) {
      
        this.setState({ panverify_st: 2 })
        if (e.target.value.length <= 10) {
           // this.setState({ panno: e.target.value.toString().toUpperCase(), errtype: 0, errorMsg: "" });
           this.setState({ panno: e.target.value,errtype: 0, errorMsg: "" });

            if (e.target.value.length === 10) {

                this.setState({ aadharnobuttonst: false })
            }
        }

    }
  render() {
    const { classes } = this.props;
    return (

      <Grid  container className={classes.page1card} >
       
     <Typography className={classes.card_tit} >Sign-up now</Typography>
  
  
     
     <Grid style={{ width: '100%', marginLeft: '5px' }}>
                                            <FormControl style={{ width: '100%' }} variant="standard">
                                                <TextField size="small" variant="standard"

                                                    fullWidth
                                                    sx={{
                                                        '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                                                        '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                                        '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                                                    }}
                                                    
   InputLabelProps={{
    sx: {
      // set the color of the label when not shrinked
      color: "#FFFFFF",
      fontFamily: 'cardiuma-regular', fontSize: '14px',
      [`&.${inputLabelClasses.shrink}`]: {
        // set the color of the label when shrinked (usually when the TextField is focused)
        color: "#FFFFFF"
      }
    }
}}
                                                    label='Enter PAN number'
                                                    value={this.state.panno}

                                                    InputProps={{
                                                        classes: { input: classes.input },
                                                        maxLength: 10,
                                                        style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                                                    }}
                                                    onChange={(e) => this.GetPanNoNumber(e)}
                                                ></TextField>
                                                {this.state.errtype === 4 &&
                                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                                }
                                            </FormControl>
                                        </Grid>

                                        <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
<Grid style={{width:'80%'}}>    
                                        <Button
                                        disabled={this.state.aadharnobuttonst}
                                        fullWidth className={this.state.aadharnobuttonst ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}
                                        style={{ marginTop: '20px', }}
                                        onClick={() => this.ContinuePan()}
                                    >Continue

                                    </Button>
                                    </Grid>
                                    </Grid>
                                    <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>                           
    
  </Grid>
  


    )
  }
}
export default (withStyles(styles)((pan)));