import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import { httpRequest } from '../../../ui-utils/api'

import * as CryptoJS from 'crypto-js';

import step2mp3 from '../../../ui-assets/step_2.mp3'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { inputLabelClasses } from "@mui/material/InputLabel";
import {
     Typography, Grid,   FormControl,
    TextField, Box,Modal, FormHelperText,Button,
    RadioGroup,Radio,FormControlLabel//added on 25/08/2024 To show yes or no For NRI 
} from '@mui/material'
const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
    formcontrol_width:
    {
        width: '100%',
        "@media only screen and (max-device-width: 480px)": {
            width: '90%', marginTop: '13%',
        },
    },
    webcamdesk: {
        display: 'flex',
        justifyContent: 'center',
        "@media only screen and (max-device-width: 480px)": {
            display: 'none'

        }
    },
    webcammob: {
        display: 'none',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex',
            justifyContent: 'center'

        }
    },
    sigCanvas:{
        background:'#ffffff'
    },
    shutter:{
        marginTop:'50px',
        width:'100%',
        height:'100vh',
        background:'red',
        borderTopLeftRadius :'8px',
        borderTopRightRaduis:'8px',
    },


  
};

const styles = theme => ({
    page1card_1: {
        width: '100%',
     
        background: '#1B2636',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '80px 0px 0px 0px',

        }
    },
    mobilebar:{
         
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex', width: '100%',padding:'14px',background:'#F3D45E',justifyContent:'center',alignItems:'center'
        },

     },
     page1card: {
        width: '100%',
      
        background: '#1B2636',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '90px 16px',

        }
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    page0titcard: {
        width: '90%', display: 'flex', justifyContent: 'center', marginTop: '10px',
        "@media only screen and (max-device-width: 480px)": {
            marginTop: '10px',
            display: 'flex', justifyContent: 'flex-start'
        }
    },
    page0tit: {
        marginTop: '15px', color: '#999999', fontFamily: 'cardiuma-regular !important', fontSize: '13px',
        "@media only screen and (max-device-width: 480px)": {
            display: 'flex', justifyContent: 'flex-start', textAlign: 'left',
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    //added on 24/08/24 Aadhaar yes no styling 
    psi_tit:{
        color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '18px !important',
        "@media only screen and (max-device-width: 480px)": {
            fontSize: '18px !important',
        },
    },
    upload_tit:{
        color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
    },
})

 class aadharno extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
                     emailid:'',
            aadharno: '',
            citizen:'1',//added on 24/08/24 To validate NRI. 
            client_id:'',
            islodding:false,
            playwav:false,
            audio_step2 : new Audio(step2mp3),
            aadhar:"true"////added on 25/08/2024 To store yes or no For NRI 
        }



    }

    GetaadharnoNumber(e) {
      
        if (e.target.value.length <= 12) {
            this.setState({ aadharno: e.target.value, errtype: 0, errorMsg: "" });

            if (e.target.value.length === 12) {
                this.setState({ aadharnobuttonst: false })
            }
        
    }

}
    
closeContactModal() {
    this.setState({ showerr: false })
}


Continue2 = async () => {
    const { aadharno ,aadhar} = this.state
    let isok = 0
   //added to To show yes or no. 
    let rd1 = localStorage.getItem('reg_data') || '{}'
    let rd2 = JSON.parse(rd1)
    let emailid=rd2.emailid
let mobileno=rd2.mobileno
let citizen=rd2.citizen
    if(aadhar==="true")
    {
    if (!aadharno.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 3, errorMsg: "Enter the Aadhar Number" })


    }




    if (aadharno.length !== 12 && isok === 0) {

        isok = 1
        this.setState({ errtype: 3, errorMsg: "Enter valid Aadhar Number" })


    }





    if (isok === 0) {



   
        const { aadharno   } = this.state
       
       // let maskedaadharno = aadharno[0] + aadharno[1] + aadharno[2] + aadharno[3] + "-" + aadharno[4] + aadharno[5] + aadharno[6] + aadharno[7] + "-" + aadharno[8] + aadharno[9] + aadharno[10] + aadharno[11]
        this.setState({ maskedaadharno: aadharno, islodding: true })

        let payload = {
            "aadharno": CryptoJS.AES.encrypt(aadharno, "96358214256984120").toString(),
            "emailid": emailid,
            "mobileno": mobileno,
            "citizen":citizen

        }
        this.setState({ islodding: true })
        try {
            const otp = await httpRequest({
                endPoint: `/api/verfy/Getaadharotp`,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            this.setState({ islodding: false })
            if (otp === "API_ERROR") {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }
            if (otp.status === 200) {
                let obj={}
                let maindata=JSON.parse( rd1)
                for (var key in maindata) {
            
            let f=0
            if(key==="client_id")
            {
                obj[key]=otp.client_id
                f=1
            }
            if(key==="aadharno")
            {
                obj[key]=aadharno
                f=1
            }
          
            
            if(f===0)
             {
                    obj[key]=maindata[key]
            }
            
                }
            
                localStorage.removeItem('reg_data')
                localStorage.setItem('reg_data', JSON.stringify(obj))

            
                                        window.ChangePageNo (2)


            } else {
                this.setState({ errtype: 3, errorMsg: otp.message })
            }
        } catch (error) {

            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

        }


    }
    }else{
//added for Aadhaar no case. 
        let rd1 = localStorage.getItem('reg_data') || '{}'
            let rd2 = JSON.parse(rd1)
        let aadharno1=rd2.id
        let al=parseInt(12)-parseInt(aadharno1.length);
        console.log(al)
        aadharno1="0".repeat(al)+aadharno1
        console.log('aadharno1***',aadharno1)
        let payload = {
            "id": CryptoJS.AES.encrypt(aadharno1, "96358214256984120").toString(),
            "emailid": emailid,
            "mobileno": mobileno,
            "citizen":citizen

        }

        
            this.setState({ islodding: true })
            try {
                const otp = await httpRequest({
                    endPoint: `/api/nri/noaadhar`,
                    method: "post",
                    instance: "instanceTwo",
                    requestBody: payload,
                });
                this.setState({ islodding: false })
                if (otp === "API_ERROR") {
                    this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
                }
                if (otp.status === 200) {
                    window.ChangePageNo(3)
                }
        } catch (error) {
            
        }

       
    }
}
componentDidMount = async () => {
   //added on 25/08/24 To skip  Aadhaar validation for NRI. 
    let rd1 =  JSON.parse(localStorage.getItem('reg_data') || '{}')
    
    this.setState({citizen:rd1.citizen})
    
    setTimeout(
        function() {
            this.state.audio_step2.play()
        }
        .bind(this),
        2000 //Delay time is increased. To avoid overlapping on 11/08/24
    );
        
    }
   //I need to handle yes or no. 
    handleOption(e)
    {
       
        this.setState({ aadhar: e.target.value })

       
    }
  render() {
    const { classes } = this.props;
    return (
      <div>  
        
  
        <Grid className={classes.page1card} >
      <Typography className={classes.card_tit} >Sign-up now</Typography>

      <Grid  >
        {/* To render yes or no for NRI.  */}
{
    this.state.citizen===1 && 
    <div>
         <Typography className={classes.psi_tit}>Do you have aadhar card?</Typography>
         <Grid style={{marginTop:'1%',width:'100%',display:'flex',alignItems:'center'}}>
              <RadioGroup
              row
              defaultValue="true"
              value={this.state.aadhar}

style={{marginLeft:'5%'}}
              onChange={e =>
                  this.handleOption(e)


              }
          >
              <FormControlLabel value="true" control={<Radio
                  disableripple
                
                  sx={{
                      color: "#999999",
                      "&.Mui-checked": {
                          position: "relative",
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              color: "yellow"
                          },
                          "&::after": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#F74542",

                              borderColor: "#ffffff",
                              color: "yellow"
                          },

                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                              stroke: '#ffffff',
                              strokeWidth: 0.5,
                          },
                      }

                  }}
              />} label={<Grid> <Typography className={classes.upload_tit}>Yes</Typography></Grid>}/>
              <FormControlLabel value="No" control={<Radio

                 
                  sx={{
                      color: "#999999",
                      "&.Mui-checked": {
                          position: "relative",
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              color: "yellow"
                          },
                          "&::after": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#F74542",

                              borderColor: "#ffffff",
                              color: "yellow"
                          },

                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                              stroke: '#ffffff',
                              strokeWidth: 0.5,
                          },
                      }

                  }}
              />} label={
              
                <Grid>
             <Typography className={classes.upload_tit}>No</Typography>
              </Grid>
              } />
            






          </RadioGroup>
            </Grid>
    </div>
}


          <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '20px' }}>
              <FormControl style={{ width: '100%' }} variant="standard">
                  <TextField size="small" variant="standard"
autoFocus={true}
                      type="number"
                      fullWidth
                      sx={{
                          '& .MuiInput-input': {
                              '-moz-appearance': 'textfield',
                              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                              }},
                          '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                          '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                      }}
                      InputLabelProps={{
                        sx: {
                          // set the color of the label when not shrinked
                          color: "#FFFFFF",
                          fontFamily: 'cardiuma-regular', fontSize: '14px',
                          [`&.${inputLabelClasses.shrink}`]: {
                            // set the color of the label when shrinked (usually when the TextField is focused)
                            color: "#FFFFFF"
                          }
                        }
                    }}
                      label='Enter your Aadhaar No'
                      value={this.state.aadharno}

                      InputProps={{
                          classes: { input: classes.input },
                          maxLength: 12,
                          style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                      }}
                      onChange={(e) => this.GetaadharnoNumber(e)}
                  ></TextField>
                  {this.state.errtype === 3 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }
              </FormControl>
          </Grid>

                
      </Grid>
       
      <Grid className={classes.page0titcard}>
          <Typography className={classes.page0tit} >You will receive an OTP on your Aadhar linked mobile number</Typography>

      </Grid>
  
<Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
<Grid style={{width:'100%'}}>
      <Button
          disabled={this.state.aadharnobuttonst}
          fullWidth className={this.state.aadharnobuttonst ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}
          style={{ marginTop: '20px', }}
          onClick={() => this.Continue2()}
      >Continue

      </Button>
      </Grid>
      </Grid>
  </Grid>
  <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
</div>
    )
  }
}
export default (withStyles(styles)((aadharno)));