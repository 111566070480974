
import React, { useEffect, Suspense, lazy } from 'react'
import { Grid } from '@mui/material'
import { httpRequest } from '../../ui-utils/api'
import { publicIpv4 } from 'public-ip'
import './campaigntwo.css'

// Lazy load components
const CustomerInfo = lazy(() => import('./CustomerInfo/CustomerInfo'))
const Aboutus = lazy(() => import('./Aboutus/Aboutus'))
const Products = lazy(() => import('./Products/Products'))
const MultiExchange = lazy(() => import('./MultiExchange/MultiExchange'))
const Footer = lazy(() => import('../landingpage/fotter'))


const CampaignTwo = () => {

  useEffect(() => {
    window.Changemytab(5)
  }, [])

  useEffect(() => {
    const postVistorsData = async () => {
      try {
        let ipAddress = await publicIpv4()

        const result = await httpRequest({
          endPoint: `api/users/count`,
          method: "post",
          instance: "instanceOne",
          requestBody: {
            ipAddress, pageName: "ad_campaign"
          }
        })

        console.log(result, "PAGE VISITORS")

      }

      catch (err) {
        console.log("Error in visitors post API: ", err)
      }
    }

    if (window.location.pathname === '/ad_campaign') {
      postVistorsData()
    }

  }, [])

  return (
    <Grid>
      <Suspense fallback={<div>Loading...</div>}>
        <CustomerInfo />
        <Aboutus />
        <Products />
        <MultiExchange />
        <Footer />
      </Suspense>
    </Grid>
  )
}

export default CampaignTwo