import { Typography, Grid, useMediaQuery, Box, Divider, TextField, Button, Link } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'

import logo from '../../ui-assets/adcampone.png'
import logo2 from '../../ui-assets/adcamptwo.svg'
import congBull from '../../ui-assets/new_bull.svg'
import aniGif from '../../ui-assets/Bullbg.gif'

const WebView = ({
    toggle, anime, history, referrer, userInfo, handleChangeUser, error, errorMessage, handleSubmit, is1000px
}) => {
    return (
        <Grid
            container sx={{ minHeight: '100vh' }}
            className='bgimg1'
        >

            {/* Left Section */}
            <Grid
                item
                md={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    display='flex'
                    justifyContent='center'
                    mt='50px'
                >
                    <Box
                        className='bgboxweb'
                    >
                        <Typography
                            className='bgbxtxtweb'
                        >
                            We are one of the most experienced stock Broking houses of India
                        </Typography>
                    </Box>

                    <Box
                        className='bgboxweb1'
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginTop: '14px',
                                position: 'relative',
                            }}
                        >
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    height: '1px',
                                    background: 'linear-gradient(90deg, rgba(217, 217, 217, 0) 35%, #F3D45E 100%)',
                                    marginRight: '8px',
                                }}
                            />
                            <Typography
                                className='weoffrweb'
                            >
                                We Offer
                            </Typography>
                            <Box
                                sx={{
                                    flexGrow: 1,
                                    height: '1px',
                                    background: 'linear-gradient(270deg, rgba(217, 217, 217, 0) 35%, #F3D45E 100%)',
                                    marginLeft: '8px',
                                }}
                            />
                        </Box>

                        <Grid container display='flex'
                            justifyContent='center' padding={0.5} gap={4} ml="5px">
                            <Grid item>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    className='offrtxtweb'
                                >
                                    <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                    Stock Broking
                                </Typography>
                            </Grid>

                            <Grid item>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                    className='offrtxtweb'
                                >
                                    <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                    Demat Account
                                </Typography>
                            </Grid>


                            <Grid item>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center', }}
                                    className='offrtxtweb'
                                >
                                    <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                    Wealth Vaults
                                </Typography>
                            </Grid>
                        </Grid>


                        <Grid container padding={1} justifyContent='center'>
                            <Grid item>
                                <Typography
                                    sx={{ display: 'flex', alignItems: 'center', }}
                                    className='offrtxtweb'
                                >
                                    <CircleIcon sx={{ fontSize: '8px', color: 'white', marginRight: '8px' }} />
                                    Expert Advice By SEBI Registered Advisors
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>

            {/* Right Section */}
            <Grid
                item
                md={6}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    {toggle ? (
                        <Box
                            className='boxwebone'
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                                mt='50px'
                            >
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        height: '1px',
                                        background: 'linear-gradient(90deg, rgba(217, 217, 217, 0) 25%, #F3D45E 100%)',
                                        marginRight: '30px',
                                    }}
                                />
                                <Typography
                                    className='weoffrweb'
                                >
                                    Thank You Very Much
                                </Typography>
                                <Box
                                    sx={{
                                        flexGrow: 1,
                                        height: '1px',
                                        background: 'linear-gradient(270deg, rgba(217, 217, 217, 0) 25%, #F3D45E 100%)',
                                        marginLeft: '30px',
                                    }}
                                />
                            </Box>

                            <Grid
                                container
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    position: 'relative',
                                    top: '20%',
                                }}
                            >

                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        padding: 5,
                                        position: 'relative',
                                    }}
                                >
                                    <img src={congBull} alt='congBull' style={{ position: 'relative' }} />
                                    {anime && <img
                                        src={aniGif} alt='congBull'
                                        style={{ position: 'absolute', top: '0px', }}
                                    />}
                                </Box>

                                <Grid
                                    item
                                >
                                    <Typography
                                        className='shemrph'
                                        // padding={4}
                                        textAlign='center'
                                    >
                                        Our team will connect with you soon for further communication
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    flexDirection="column"
                                    sx={{
                                        textAlign: 'center',
                                        width: '100%',
                                        height: '200px'
                                    }}
                                    className='sgnupnw'
                                >

                                    <Button
                                        className='subbtn'
                                        onClick={() => history.push(`/signup?referral=${referrer ? referrer : ""}`)}
                                        sx={{ marginTop: "20px" }}
                                    >
                                        Sign Up Now
                                    </Button>

                                    <Link
                                        className='explor'
                                        href='https://bullforce.co/'
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        sx={{ padding: '30px' }}
                                    >
                                        Explore More
                                        <ArrowForwardIosIcon sx={{ fontSize: '18px' }} />
                                    </Link>

                                </Grid>
                            </Grid>
                        </Box>
                    ) : (
                        <Box
                            className='boxwebone'
                        >
                            <Grid container padding={1} gap='5px' sx={{
                                width: "auto", height: "auto",
                                position: 'relative',
                                top: '25px',
                            }}  >
                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={logo2} alt='logo' width={is1000px ? 90 : 65} style={{ margin: "10px 0 5px 0" }} />
                                    <img src={logo} alt='logo' width={is1000px ? 200 : 150} style={{ margin: "25px 0 0 5px" }} />
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', }}>
                                    <Divider className='divdr' />
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                    <Typography className='malli' >
                                        MALIRAM MAKHARIA FINSTOCK PRIVATE LIMITED
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', }}>
                                    <Typography className='sinceweb'>
                                        Since 1996
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                mt='25px'
                                padding={1.5}
                                sx={{
                                    width: "auto", height: "auto",
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        position: 'relative',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            height: '1px',
                                            background: 'linear-gradient(90deg, rgba(217, 217, 217, 0) 35%, #F3D45E 100%)',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            flexGrow: 1,
                                            height: '1px',
                                            background: 'linear-gradient(270deg, rgba(217, 217, 217, 0) 35%, #F3D45E 100%)',
                                        }}
                                    />
                                </Box>
                            </Grid>

                            <Grid
                                item
                            >
                                <Typography
                                    className='shemrph'
                                    padding={3}
                                    textAlign='center'
                                >
                                    {/* Line break - 17/09/24 - Dev Srini */}
                                    Please share your email / phone number. <br />
                                    One of our executive will connect and complete the process.
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                padding={1}
                            >
                                <Grid item textAlign="center">
                                    <TextField
                                        className="textfld"

                                        id="outlined-basic"
                                        label="Email ID / Phone Number"
                                        variant="outlined"

                                        InputLabelProps={{
                                            sx: {
                                                color: '#000',
                                                fontSize: is1000px ? '24px' : '16px',
                                                fontFamily: 'cardiuma-regular',
                                                fontWeight: 400,
                                                padding: is1000px && 1
                                            },
                                        }}

                                        value={userInfo}
                                        onChange={(e) => handleChangeUser(e)}
                                    />
                                    {error && <Typography
                                        sx={{ fontFamily: 'cardiuma-regular', fontSize: '14px', fontFamily: '400', color: 'red', padding: '10px' }}
                                    >{errorMessage}</Typography>}
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                padding={error ? 2 : 1}
                            >
                                <Button
                                    className='subbtn'
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sx={{ textAlign: 'center' }}
                            >
                                <Typography
                                    className='shemrph'
                                    padding={2}
                                >
                                    OR
                                </Typography>
                            </Grid>

                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                padding={3}
                                sx={{ padding: '0 0 50px 0' }}
                            >
                                <Button
                                    className='subbtn'
                                    onClick={() => history.push(`/signup?referral=${referrer ? referrer : ""}`)}
                                >
                                    Sign Up Now
                                </Button>
                            </Grid>
                        </Box>
                    )}
                </Box>
            </Grid>

        </Grid>
    )
}

export default WebView