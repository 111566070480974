import React, { Component } from 'react'


import diplomate_card from "../../ui-assets/diplomate_card.svg";
import teamwork from "../../ui-assets/Teamwork_black.svg";
import teamwork_mobile from "../../ui-assets/teamwork_mobile.svg";

import diplomate_main from "../../ui-assets/diplomate_main.webp";
import diplomate_main_mobile from "../../ui-assets/lady_working_mob.webp";
import man_web from "../../ui-assets/man_web.webp";
import social_media_web from "../../ui-assets/social_media_web.webp";
import man_mobile from "../../ui-assets/man_mobile_screen.webp";
import social_media_mobile from "../../ui-assets/social_media_mobile.webp"

import v1 from "../../ui-assets/v1.svg";
import v2 from "../../ui-assets/v2.svg";
import v3 from "../../ui-assets/v3.svg";
import { withStyles, styled } from "@mui/styles";
import { Close, WrapText } from '@mui/icons-material';

import { httpRequest } from '../../ui-utils/api'
import './diplomats.css'
import Fotter from '../landingpage/fotter';
import validator from 'validator'
import { Grid, Typography, Button, TextField, Snackbar, SnackbarContent, IconButton, FormControl, FormHelperText } from '@mui/material'
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'

const meta = {
    title: 'Diplomats | BullForce',
    description: 'Join BullForce Diplomat: Become a brand ambassador, monetize your network, and enhance your influence in trading. Get exclusive benefits and grow with BullForce.',
    canonical: 'https://bullforce.co/diplomats',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, diplomats, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const CssTextField = styled(TextField)({
    borderRadius: '8px',
    '& label.Mui-focused': {
        color: '#999999',

    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#999999',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#999999',
        },
        '&:hover fieldset': {
            borderColor: '#999999',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#999999',
        },
    },
});

const styles = theme => ({

    back_img: {

        backgroundSize: "cover !important",
        backgroundRepeat: "no-repeat !important",
        backgroundPosition: "center center !important",
        height: "75vh",

        backgroundImage: `url(${diplomate_main})`,
        //backgroundImage: `url(${diplomate_main}),linear-gradient(45deg, rgba(17, 169, 255, 0.67), rgba(1, 46, 101, 0.7))`,


        //backgroundBlendMode: 'multiply',
        width: "auto",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end !important',
        "@media screen and (max-width:480px)": {

            height:'500px !important',
            backgroundImage: `url(${diplomate_main_mobile})`,
        },
        "@media screen and (max-width:350px)": {

            height:'365px !important',
            backgroundImage: `url(${diplomate_main_mobile})`,
        }


    },

    teamworkcard: {
        display: 'flex', width: '100%', justifyContent: 'center', padding: '0px',

        "@media screen and (max-width:960px)": {


            display: 'none',

        }
    },

    teamworkcard1: {

        display: 'none',
        "@media screen and (max-width:960px)": {

            display: 'flex', width: '100%', justifyContent: 'center', padding: '0px',


        }
    },

    welcomecard: {
        width: '100%',display:'flex !important',justifyContent:'center !important', 
        "@media screen and (max-width:960px)": {
            display:'flex !important',justifyContent:'center !important', 
            width: '100%',
          

        }
    },
    welcomecardnew: {
        width: '100%', display:'flex !important',justifyContent:'center !important', 
        "@media screen and (max-width:960px)": {
            width: '100%',
            display:'flex !important',
            justifyContent:'center !important' 

        }
    },

    welcomecard1: {
        marginTop: '20px', width: '100%', display: 'flex', justifyContent: 'center',
        "@media screen and (max-width:960px)": {


         

        }
    },
    welcome_txt:{
        textAlign: 'center', color: '#ffffff', fontSize: '58px !important', fontFamily: 'cardiuma-Bold !important', padding: '0px 150px 0px 150px',

        // h1 tags margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0, marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '44px !important',
            padding: '15px 15px 0px 15px', color: '#ffffff',
        },
        "@media screen and (max-width:480px)": {
            fontSize: '28px !important',
            padding: '15px 15px 0px 15px', color: '#ffffff',
        }

    },
    welcome_txtnew:{
        textAlign: 'center !important', width:'549px',color: '#ffffff', fontSize: '24px !important', fontFamily: 'cardiuma-regular !important',justifyContent:'center',
        //  padding: '20px 32px 0px 32px',

        marginBlockStart: 0, marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            width:'450px',
            fontSize: '22px !important',
            padding: '10px 8px 0px 8px !important', color: '#ffffff',
        },
        "@media screen and (max-width:480px)": {
            width:'292px',
            fontSize: '14px !important',
            padding: '10px 8px 0px 8px !important', color: '#ffffff',
        }
    },
   
    c_cardmain: {
        display: 'flex', width: '100%', padding: '40px 0px 40px 40px', background: 'linear-gradient(180deg, #0B1420 0%, #D8E2EC 100%) !important',

        "@media screen and (max-width:960px)": {
            background: 'linear-gradient(180deg, #0B1420 0%, #D8E2EC 100%) !important',
            width: '100%', padding: '0px',
            display: 'inline'


        }
    },


    c_cardmain_sub1: {
        
        "@media screen and (max-width:960px)": {
            background: 'linear-gradient(180deg, #0B1420 0%, #D8E2EC 100%) !important',
            width: '100%',
            padding: '16px 20px 16px 20px'
        }
    },

    c_cardmain_sub2: {
        width: '40%', marginLeft: '50px', display: 'flex', justifyContent: 'center', alignItems: 'center',
        "@media screen and (max-width:960px)": {
            display: 'none'
        }
    },
    c_cardmain_sub3: {
        display: 'none',

        "@media screen and (max-width:960px)": {
            width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#ffffff',
            padding: '75px 16px 10px 16px'
        }
    },
    c_cardmain_tit: {
        color: '#363B68', fontSize: '28px', fontFamily: 'cardiuma-regular !important ',
        "@media screen and (max-width:960px)": {
            fontSize: '18px',
        }
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #ffffff inset",
            WebkitTextFillColor: '#1B2636'

        }
    },
    DiplomtsBtn: {
        background: "rgba(244, 213, 83, 1) !important",
        padding: "16px 24px !important",
        borderRadius: "38px !important",
        fontStyle: "normal !important",
        fontWeight: "100 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "rgba(51, 51, 51, 1) !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },

    submitBtn: {
        background: "#F74542 !important",
        padding: "16px 24px !important",
        borderRadius: "38px !important",
        fontStyle: "normal !important",
        fontWeight: "100 !important",
        fontSize: "18px !important",
        lineHeight: "18px !important",
        color: "#ffffff !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important",
        "@media screen and (max-width:960px)": {
            background: "#F74542 !important",
            padding: "12px 24px !important",
            borderRadius: "38px !important",
            fontSize: "14px !important",
        }
    },
    errtxt: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    ourdip_tit: {
        textAlign: 'center', color: '#ffffff', fontSize: '68px !important', fontFamily: 'cardiuma-Bold !important',
        marginBlockStart: 0, marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
            textAlign: 'left !important'
        }
    },
    ourdip_card1: {
        width: '30%',
        "@media screen and (max-width:960px)": {
            width: '100%',
            padding: '0px 0px'
        }
    },
    ourdip_card2: {
        width: '30%',
        "@media screen and (max-width:960px)": {
            display: 'none'
        }

    },
    signupcard: {
        position: 'relative', 
        overflow: 'hidden', 
        height: 'auto',
        width: '100%' ,
        padding: '0px 0px 0px 0px',
       
        "@media screen and (max-width:1200px) and (min-width:900px)": {
            height:'650px'
        },
        "@media screen and (max-width:900px)": {
            background: '#ffffff !important',
            padding: '0px 0px 0px 0px',
            height:'650px !important'
        }
    },
    absoulte_img : {
        position: 'relative',
        objectFit: 'cover', 
        backgroundRepeat:'no-repeat',
        "@media screen and (max-width:1200px) and (min-width:900px)": {
            height:'650px'
        }
    },
    absoulte_modal: {
        position:'absolute',
        top:'0px',
        width:'100% !important',
        // left:'10%',
        padding:'20px 0px 250px 0px',    
    },
    signup_tit: {
        color: '#ffffff', fontSize: '32px !important', fontFamily: 'cardiuma-Bold !important',marginTop:'20px !important',
        marginBlockStart: 0, marginBlockEnd: 0,
        "@media screen and (min-width:1720px)" : {
            marginTop:'50px !important',
    },
        "@media screen and (max-width:960px)": {
            fontSize: '22px !important'
        }
    },
    signup_tit2: {
        color: '#ffffff', fontSize: '24px !important', fontFamily: 'cardiuma-regular !important',marginTop:'10px !important',
        marginBlockStart: 0, marginBlockEnd: 0,textAlign:'center !important',padding:'0px 10px 0px 10px !important',
    // "@media screen and (min-width:1720px)" : {
    //             marginTop:'50px !important',
    //     },
        "@media screen and (max-width:960px)": {
            fontSize: '16px !important'
        }
    },
    signupinfo_card: {
        marginTop: '30px', width: '100%', display: 'flex', justifyContent: 'center !important',
        "@media screen and (max-width:960px)": {
            marginTop: '24px',
        }

    },
    signupinfo_card1: {
        width: '50% !important', background: 'rgba(255, 255, 255, 0.9) ', backgroundRepeat:'no-repeat', backgroundPosition: 'center', borderRadius: '8px', padding: '50px 55px',
        "@media screen and (max-width:1450px) and (min-width:900px)": {
            width: '60% !important', padding: '30px 30px 30px 30px',margin: '0px 16px !important'
        },

        "@media screen and (max-width:900px)": {
            width: '95% !important', padding: '0px 16px', height:'100%',margin: '0px 16px !important'
        }

    },
    dip_txt:{
        color: '#ffffff', fontSize: '28px !important', fontFamily: 'cardiuma-regular !important',
        "@media screen and (max-width:960px)": {
            fontSize: '18px !important',
        }
    },
    dip_txt1:{
        color: '#ffffff', fontSize: '28px !important', fontFamily: 'cardiuma-regular !important',paddingTop:'15px',
        "@media screen and (max-width:960px)": {
            fontSize: '18px !important',
        }
    },
    dip_imgcard1:{
        width: '100%', display: 'flex', justifyContent: 'center',
        "@media screen and (max-width:960px)": {
           display:'none !important'
        }
    },
    dip_img2:{
        width: '100%', display: 'flex', justifyContent: 'center',padding:'16px !important',
        "@media screen and (min-width:960px)": {
           display:'none !important'
        }
    },
    dip_main:{
        width: '100%', background: '#111', height: 'auto', padding: '50px 0px 0px 0px',
        "@media screen and (max-width:960px)": {
            width: '100%', 
            padding:'16px'
        }
    },
    dip_main1:{
        width: '100%', display: 'flex', padding: '20px 0px 100px 0px',
        "@media screen and (max-width:960px)": {
            width: '100%', 
            padding: '0px 0px 0px 0px',
        }

    },
    dip_main2:{
    display: 'flex', width: '100%', justifyContent: 'center',
    // "@media screen and (max-width:960px)": {
    //   display:'NOne'
    // }
    },
    dip_main3:{
        display: 'None', width: '100%', justifyContent: 'center',
        "@media screen and (max-width:960px)": {
          display:'flex',width: '100%', padding:'16px',justifyContent:'flex-start'
        }
        },
        dip_vfont:{

             color: '#00ACFE', fontSize: '22px !important', fontFamily: 'cardiuma-Bold !important'
   

        },
        dip_vfont_disp:{

            color: '#FFFFFF', fontSize: '16px !important', fontFamily: 'cardiuma-regular !important'
  

       },
       button_submit:{
            margin:'25px !important',
            "@media screen and (max-width:1300px)": {
                margin:'15px !important'
       }
    },
    secondGrid:{
        "@media screen and (max-width:540px)": {
            display: 'none',
        }
    },
    gridSecond:{
        width:'100% !important',
        height:'782.8px !important',
        display:'flex !important', 
        flexDirection:'column !important', 
        position:'relative !important', 
        background:'#ffffff !important',
        "@media screen and (max-width:940px)": {
            height:'600px !important'
        }
    },
    man_web_img:{
        width: '500px !important',
        position:'absolute !important',
        bottom:'0px !important',
        right: '0px !important', 
        float: 'right !important',
        "@media screen and (max-width:940px)": {
            width: '350px !important',
            height:'500px !important'
        },
        "@media screen and (max-width:800px)": {
            width: '340px !important',
            height:'490px !important'
        },
        "@media screen and (max-width:750px)": {
            width: '310px !important',
            height:'460px !important'
        }
    },
    first_grid:{
        display:'flex !important', 
        flexDirection:'row !important', 
        margin:'80px 0px 0px 0px !important',
    },
    responsive_img:{
        width:'100% !important',
        height:'100% !important',

            "@media screen and (max-width:1350px) and (min-width:960px)": {
                width:'95% !important',
                height:'95% !important'
            }
    },
    social_text:{
        color:'#363B68 !important',
        fontSize:'32px !important', 
        fontFamily: 'cardiuma-regular !important', 
        marginRight:'25% !important',
        "@media screen and (max-width:1600px)": {
            marginRight:'30% !important',
        },
        "@media screen and (max-width:1400px)": {
            marginRight:'35% !important',
            fontSize:'29px !important'
        } ,
        "@media screen and (max-width:1200px)": {
            marginRight:'45% !important',
            fontSize:'25px !important'
        },
        "@media screen and (max-width:940px)": {
            marginRight:'0% !important',
            fontSize:'20px !important'
        },
        "@media screen and (max-width:640px)": {
            marginRight:'0% !important',
            fontSize:'16px !important'
        }       
    },
    media_img:{
        height: '224px !important',
        width: '225px !important ', 
        margin:'0px 55px 0px 60px !important',
        "@media screen and (max-width:1250px)":{
            height: '180px !important', 
            width: '180px !important', 
            margin:'0px 30px 0px 60px !important',
        },
        "@media screen and (max-width:940px)":{
            height: '120px !important', 
            width: '120px !important', 
            margin:'0px 30px 0px 60px !important',
        }
    },
    fill_text_grid:{
        display:'flex !important', 
        flexDirection:'column !important', 
        margin:'130px 0px 0px 120px !important',
        color:'#363B68 !important',
        "@media screen and (max-width:1200px)": {
            margin:'100px 0px 0px 100px !important',
        },
        "@media screen and (max-width:1040px)": {
            margin:'100px 0px 0px 70px !important',
        },
        "@media screen and (max-width:940px)": {
            margin:'50px 0px 0px 40px !important',
        },
        "@media screen and (max-width:560px)": {
            margin:'50px 0px 0px 20px !important',
        }

    },
    text1:{
        fontSize:'32px !important', 
        fontFamily: 'cardiuma-semibold !important',
        "@media screen and (max-width:1200px)": {
            fontSize:'28px !important',
        },
        "@media screen and (max-width:940px)": {
            fontSize:'22px !important',
        }
    },
    text2:{
        fontSize:'48px !important', 
        fontFamily: 'cardiuma-bold !important',
        "@media screen and (max-width:1200px)": {
            fontSize:'44px !important',
        },
        "@media screen and (max-width:1040px)": {
            fontSize:'40px !important',
        },
        "@media screen and (max-width:940px)": {
            marginRight:'0% !important',
            fontSize:'26px !important'
        } 
    },
    fill_text:{
        fontSize:'32px !important', 
        fontFamily: 'cardiuma-regular !important',
        marginRight:'37% !important',
        "@media screen and (max-width:1400px)":{
            marginRight:'40% !important',
            fontSize:'29px !important'
        },
        
        "@media screen and (max-width:1250px)": {
            fontSize:'25px !important',
            marginRight:'50% !important',
        },
        "@media screen and (max-width:1040px)": {
            marginRight:'54% !important',
        },
        "@media screen and (max-width:940px)": {
            marginRight:'42% !important',
            fontSize:'20px !important'
        },
         "@media screen and (max-width:750px)": {
            marginRight:'45% !important',
        }, 
        "@media screen and (max-width:640px)": {
            marginRight:'50% !important',
            fontSize:'16px !important'
        },
    },
    secondGridMobile:{
        backgroundColor:'#ffffff !important',
        // height:'767px !important',
        height:'auto !important',
        "@media screen and (min-width:541px)": {
            display: 'none',
        }
    },
    social_text_small:{
        textAlign:'center !important',
        color:'#363B68 !important',
        fontSize:'18px !important', 
        fontFamily: 'cardiuma-regular !important', 
        padding:'24px 18px 0px 18px !important'
    },
    media_img_small:{
        height: '160px !important',
        width: '161px !important ', 
        margin:'24px 0px 50px 0px !important',
    },
    text1_small:{
        fontSize:'18px !important', 
        fontFamily: 'cardiuma-semibold !important',
    },
    text2_small:{
        fontSize:'24px !important', 
        fontFamily: 'cardiuma-bold !important',
    },
    fill_text_small:{
        marginRight:'40% !important',
        marginLeft:'16px !important',
        marginTop:'10px !important',
        color:'#363B68 !important',
        fontSize:'16px !important', 
        fontFamily: 'cardiuma-regular !important',
        "@media screen and (max-width:350px)": {
            fontSize:'14px !important',
            marginRight:'50% !important',
 
        }
    },
    man_small:{
        position:'absolute !important',
        right:'0px !important',
        height:'373px !important',
        width:'249px !important',
       
    }

});

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {

        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    }
};

class main extends Component {
    constructor(props) {
        super(props)


        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '',
            fname: '',
            lname: '',
            profession: '',
            errtype: 0,
            city: '',
            email: '',
            country: '',
            phoneno: '',
            socialmed: ''
        }



    }
    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {
        window.scrollTo(0, 0);
        window.Changemytab(1)
    }

    createAccount = async () => {
        const { fname, lname, profession, city, email, country, phoneno, socialmed } = this.state
        let isok = 0

        if (!fname.trim().length && isok === 0) {

            isok = 1
            this.setState({ errtype: 1, errorMsg: "Enter the Name" })

        }
        // if(!lname.length && isok === 0 ){

        //     isok = 1
        //     this.setState({errtype: 6, errorMsg: "Enter the Last Name"})
        // }
        if (!email.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 2, errorMsg: "Enter the E-Mail" })

        }

        if (validator.isEmail(email) === false && isok === 0) {

            isok = 1
            this.setState({ errtype: 2, errorMsg: "E-Mail Not Vailed" })

        }
        if (!phoneno.length && isok === 0) {

            isok = 1
            this.setState({ errtype: 3, errorMsg: "Enter the Phone Number" })

        }

        if(validator.isMobilePhone(phoneno, 'en-IN') === false && isok === 0){

            isok = 1
            this.setState({ errtype: 3, errorMsg:"Enter valid Phone Number"})

        }
        if(!city.trim().length && isok === 0 ){

            isok = 1
            this.setState({errtype: 7, errorMsg: "Enter the City"})
        }

        // if(!country.length && isok === 0 ){

        //     isok = 1
        //     this.setState({errtype: 8, errorMsg: "Enter the Country"})
        // }
        if(!profession.trim().length && isok === 0 ){

            isok = 1
            this.setState({errtype: 4, errorMsg: "Enter the Profession"})
        }

        if(!socialmed.trim().length && isok === 0 ){

            isok = 1
            this.setState({errtype: 5, errorMsg: "Enter the social media handle"})
        }



        if (isok === 0) {

            let payload = {
                fname: fname.trim(),
                lname: lname,
                profession: profession.trim(),
                city: city.trim(),
                country: country,
                phoneno: phoneno,
                email: email,
                socialmed: socialmed.trim()
            }

            try {
                const openaccount = await httpRequest({
                    endPoint: `api/user_form/diplomateaccount`,
                    method: "post",
                    instance: "instanceOne",
                    requestBody: payload,
                });
                console.log(openaccount.status)
                if (openaccount.status === 200) {

                    this.setState({
                        showerr: true, errorMsg:openaccount.message , mysnack: snackeror.mysucc,

                        fname: '', lname: '', email: '',profession:'',socialmed:'',phoneno:'',city:'',country:''
                    })

                } else {
                    this.setState({ showerr: true, errorMsg: openaccount.message, mysnack: snackeror.myerror })
                }
            } catch (error) {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror, 
                    fname: '', lname: '', email: '',profession:'',socialmed:'',phoneno:'',city:'',country:''

                })
            }

        }

    }


    render() {
        const { classes } = this.props;
        return (
            <div>
                <DocumentMeta {...meta} />
                <Grid className={classes.back_img}  >

                    <Grid style={{
                        width: '100%',
                        marginBottom: '50px'
                    }}>
                        <Grid className={classes.welcomecard}>
                            {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                            <h1 className={classes.welcome_txt}>Welcome Diplomats</h1>
                        </Grid>

                        <Grid className={classes.welcomecardnew} sx={{
                            // alignSelf:'end !important', 
                            // position:'absolute !important'
                            }}>
                        <Typography className={classes.welcome_txtnew}>This is the time to monetize your social network and personal brand</Typography>
                        </Grid>
                        <Grid className={classes.welcomecard1}>
                            <Button className={classes.DiplomtsBtn}

                                onClick={() => document.getElementById('signup').scrollIntoView()}
                            >Sign Up Now</Button>
                        </Grid>
                        
                    </Grid>
                </Grid>
                <div className={classes.secondGrid}>
                    <Grid className={classes.gridSecond} container>
                    <img src={man_web} alt={man_web}
                            className={classes.man_web_img}
                            >
                    </img>
                            {/* First grid */}
                    <Grid className={classes.first_grid} sx={{display:'flex !important', flexDirection:'row !important', margin:'80px 0px 0px 0px !important'}}>
                        <Grid>
                            <img src={social_media_web} alt={social_media_web}
                                 className={classes.media_img}>
                            </img>
                        </Grid>
                        <Grid>
                            <Typography className={classes.social_text}>Welcome to the world of “BullForce Diplomats”. BullForce diplomats is a program designed to assist our business partners to monetize the power of their social network.</Typography>
                        </Grid>
                    </Grid>
                            {/* Second grid */}
                    <Grid className={classes.fill_text_grid}>
                    <Typography className={classes.text1}>You can become a</Typography>
                    <Typography className={classes.text2}>BullForce Diplomat!</Typography>
                    <Typography className={classes.fill_text}>By following few simple steps. Please fill the form and submit. One of our team member would get in touch you and take this forward.</Typography>
                    </Grid>
                </Grid>  
                </div>  
                <div className={classes.secondGridMobile}>
                    <Grid>
                        <Grid>
                            <Typography className={classes.social_text_small}>Welcome to the world of “BullForce Diplomats”. BullForce diplomats is a program designed to assist our business partners to monetize the power of their social network.</Typography>
                        </Grid>
                        <Grid sx={{width:'100%', display:'flex !important', justifyContent:'center !important',}}>
                            <img src={social_media_web} alt={social_media_web} className={classes.media_img_small}></img>
                        </Grid>
                    </Grid>
                    <Grid ml={2} sx={{color:'#363B68 !important'}}>
                        <Typography className={classes.text1_small}>You can become a</Typography>
                        <Typography className={classes.text2_small}>BullForce Diplomat!</Typography>
                    </Grid>
                    <div>
                    <Grid sx={{position:'relative !important', width:'100%' , height:'373px !important'}}>
                        <img src={man_mobile} className={classes.man_small}></img>
                        <Typography className={classes.fill_text_small}>
                        By following few simple steps. Please fill the form and submit. One of our team member would get in touch you and take this forward.</Typography>
                    </Grid>
                    </div>
                </div>     

                <Grid className={classes.dip_main}>
                    <Grid className={classes.dip_imgcard1}>
                        <h1 className={classes.ourdip_tit}>Our Diplomats say this!</h1>
                    </Grid>
                    <Grid className={classes.dip_img2}>
                        <h1 className={classes.ourdip_tit}>Bullforce Diplomats</h1>
                    </Grid>
                    <Grid className={classes.dip_main1}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.ourdip_card1}>
                                <img src={v1} style={{ width: '100%', }} />
                                <Typography className={classes.dip_vfont} >Vineet Patel, Bangalore</Typography>
                                <Typography className={classes.dip_vfont_disp} >This is one of the coolest program I have ever seen. This is one of the best ways to
                                    contribute to our society by bringing more and more awareness on investment
                                    management. Bullforce is striving hard to make this happen !</Typography>
                            </Grid>
                            <Grid className={classes.ourdip_card2}>
                                <img src={v2} style={{ width: '100%', }} />
                                <Typography className={classes.dip_vfont} >Abdul Saud, Hyderabad</Typography>
                                <Typography className={classes.dip_vfont_disp} >Am a big fan of this “diplomat program”, I really like the way in which the program has
                                    been designed. This is working on a totally automated technology platform with very
                                    minimum human interference and assuring highest level of transparency</Typography>
                            </Grid>
                            <Grid className={classes.ourdip_card2}>
                                <img src={v3} style={{ width: '100%' }} />
                                <Typography className={classes.dip_vfont} >Suchita Hegde, Mumbai</Typography>
                                <Typography className={classes.dip_vfont_disp} >Am truly excited to be the “diplomat” of Bullforce. This is the beginning of the new trend
                                    which can be termed as “Social Investment”. This program is designed to benefit
                                    everyone in the circle. The most benefited group are the investors as they have a face to
                                    represent the brand.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                </Grid>
                <Grid id="signup" className={classes.signupcard}>
                <img src={teamwork} alt='teamwork' className={classes.absoulte_img}
                            style={{ height: '100%', width: '100%' }}>
                        </img>
                <Grid className={classes.absoulte_modal}>
                <Grid className={classes.dip_main2}>
                        <h1 className={classes.signup_tit}>Be Our Diplomat!</h1>
                    </Grid>
                    <Grid className={classes.dip_main2}>
                        <h1 className={classes.signup_tit2}>We need to capture following information</h1>
                    </Grid>

                    {/* Modal changes start by dev-trupti on 14-10-2024 */}
                    <Grid className={classes.signupinfo_card}>
                     <Grid container className={classes.signupinfo_card1} >

                                    
                        <Grid item lg={12} md={12} sm={12} xs={12}style={{ marginTop: '10px' }}>

                            <FormControl style={{width:'100%'}} variant="standard">
                                <TextField size="small" variant="standard"



                                    label='Name*'
                                    fullWidth

                                    value={this.state.fname}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },


                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 1 ? "#F74542" : '#999999', fontFamily: 'cardiuma-regular !important', fontSize: '12px' },


                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular !important', fontSize: '18px', height: '30px', },

                                    }}
                                    onChange={(e) => this.setState({ fname: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 1 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>

                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <FormControl style={{width: window.innerWidth<900 ? '100%':'90%'}} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Email*'
                                    fullWidth
                                    value={this.state.email}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 2 ? "#F74542" : '#999999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ email: e.target.value.trim().toLowerCase(), errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 2 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12} xs={12} style={{ marginTop: '10px' }} >
                            <FormControl style={{ width: '100%' }} variant="standard">
                            <TextField
                                size="small"
                                variant="standard"
                                type='number'
                                label='Contact Number*'
                                fullWidth
                                value={this.state.phoneno}
                                sx={{
                                    '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                    '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    '& input::-webkit-outer-spin-button': {
                                        display: 'none',
                                    },
                                    '& input::-webkit-inner-spin-button': {
                                        display: 'none',
                                    },
                                }}
                                InputLabelProps={{
                                    style: { color: this.state.errtype === 3 ? "#F74542" : '#999999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                }}
                                InputProps={{
                                    classes: { input: classes.input },
                                    inputProps: { 
                                        maxLength: 16 
                                    },
                                    style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' },
                                }}
                                onChange={(e) => this.setState({ phoneno: e.target.value.trim(), errtype: 0 })}
                            />

                                {this.state.errtype === 3 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <FormControl style={{width: '100%'}} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='City*'
                                    fullWidth
                                    value={this.state.city}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 7 ? "#F74542" : '#999999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ city: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 7 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>

                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Profession*'
                                    fullWidth
                                    value={this.state.profession}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 4 ? "#F74542" : '#999999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ profession: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 4 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>

                        <Grid item md={12} sm={12} xs={12} style={{ marginTop: '10px' }}>
                            <FormControl style={{ width: '100%' }} variant="standard">
                                <TextField size="small" variant="standard"
                                    label='Social Media Handle*'
                                    fullWidth
                                    value={this.state.socialmed}
                                    sx={{
                                        '& .MuiInput-underline:before': { borderBottomColor: '#92A4C1' },
                                        '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: this.state.errtype === 5 ? "#F74542" : '#999999', fontFamily: 'cardiuma-regular', fontSize: '12px' },
                                    }}
                                    InputProps={{
                                        classes: { input: classes.input },
                                        maxLength: 16,
                                        style: { color: "#0B1420", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px', },
                                    }}
                                    onChange={(e) => this.setState({ socialmed: e.target.value, errtype: 0 })}
                                ></TextField>
                                {this.state.errtype === 5 &&
                                    <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12} className={classes.button_submit}sx={{ margin: '25px', }}>
                            <Button fullWidth className={classes.submitBtn}

                                onClick={() => this.createAccount()}
                            >Submit</Button>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                
                    {/* Modal changes end by dev-trupti on 14-10-2024 */}
                </Grid>

                <Grid style={{width:'100%'}}>
                    <Fotter/>
                </Grid>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>


            </div>

        )
    }
}
export default (withStyles(styles)((main)));