
import { useEffect, useState } from 'react'
import { Grid, useMediaQuery } from '@mui/material'
import './products.css'
import { httpRequest } from '../../ui-utils/api'


// Components
import MobileView from './MobileView'
import WebView from './WebView'

const Subscription = () => {

    const isSmallScreen = useMediaQuery('(max-width:960px)')

    const [price, setPrice] = useState([])
    // Subscription price API integrated by dev-srini on 31-10-2024
    useEffect(() => {
        const productsPrice = async() => {
            try{
                const result = await httpRequest({
                    endPoint: 'api/subscription',
                    method: 'get',
                    instance: 'instanceTwo'
                })
                
                if (result.status === 200) {
                    setPrice(result.Subscription)
                   
                }
                else{
                    console.log('Error in Subscription API', result)
                }
            }
            catch(error){
                console.log('Error in Subscription API', error)
            }
        }

        productsPrice()
    }, [])

    return (
        <Grid>
            {isSmallScreen ? <MobileView price={price} /> : <WebView price={price} />}
        </Grid>
    )
}

export default Subscription