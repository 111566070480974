import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";

import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'

import {signup_funcation} from './signup_funcation'
import * as CryptoJS from 'crypto-js';
import digi from '../../../ui-assets/diglocker.svg'
import Secure from '../../../ui-assets/Secure.svg'
import { Close } from '@mui/icons-material';
import {
    Typography, Grid, Button,Modal,Box, Snackbar, SnackbarContent, IconButton
} from '@mui/material'

const snackeror = {
    myerror: {
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #b60000",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #b60000",
        borderRight: "1px solid #b60000",
        borderBottom: "1px solid #b60000",
    },
    mysucc: {
 
        backgroundColor: "#ffffff",
        borderLeft: "8px solid #8db600",
        flexWrap: "nowrap",
        color: "#005100",
        borderTop: "1px solid #8db600",
        borderRight: "1px solid #8db600",
        borderBottom: "1px solid #8db600",
    },
  
 
  
 };
const styles = theme => ({
  

    page1card: {
       width: '100%',
       paddingLeft: '20%',
  
       display:'flex',
       justifyContent:'center',
       alignItems:'center',
       top:0,
       left:0,
       "@media only screen and (max-device-width: 480px)": {
           width: '100%',
           padding: '90px 16px',

       }
   },
   card_tit: {
       color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',

       "@media only screen and (max-device-width: 480px)": {
           fontSize: '24px !important',
       },

   },
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },


   resendtxt:{
    cursor: 'pointer', textAlign: 'center', marginLeft: '15px', color: '#0083CA', fontFamily: 'cardiuma-regular !important', fontSize: '14px' 

},
texthear:{
     textAlign: 'center', color: '#F3D45E !important', fontFamily: 'cardiuma-Bold !important', fontSize: '24px' ,
     "@media only screen and (max-device-width: 480px)": {
        fontSize: '24px !important' 
     }
    
},
texthear1:{
    textAlign: 'center', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '20px',padding:'4%', 
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '16px' 
     }
   
},
texthear2:{
    textAlign: 'center', color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '20px',padding:'3%', 
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '16px' 
     }
   
},
texthear3:{
    textAlign: 'center', color: '#9A9A9A !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '12px' 
     }
},
   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },
   cong_card: {
    position: 'absolute',
    top: '55%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: 'auto',
display:'flex',
justifyContent:'center',
    outline: 'none',
    p: 4,
    "@media only screen and (max-device-width: 480px)": {
        top: '15%',
        left: '0%',
        transform: 'none',
        width: '100%',
    }
},
cong_card1: {
    width:'50%',
    "@media only screen and (max-device-width: 480px)": {
        width:'100%',
    }
},
butt_card:{
    width:'50%',display:'flex',justifyContent:'center',
    "@media only screen and (max-device-width: 480px)": {
        width:'80%',
    }
},
esign_tit1:
{
    color: '#0083CA', fontFamily: 'cardiuma-regular !important', fontSize: '16px',cursor:'pointer',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '12px',

    }
},
})

 class digilocker extends Component {
    constructor(props) {
       
        super(props)
      
       
        this.state = {
          islodding:false,
          mysnack: snackeror.mysucc,
          showerr: false,
          errorMsg: '0',
          citizen:0
        }



    }

    closeContactModal() {
        this.setState({ showerr: false })
    }
    componentDidMount = async () => {

        let rd1 = localStorage.getItem('reg_data') || '{}'
let rd2 = JSON.parse(rd1)
let citizen=rd2.citizen
this.setState({citizen:rd2.citizen})
if(rd2.stage===3)
{  

    
    let dedata = ""
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
//Setu added 06/03/24
    if(urlParams.get('data') !==null)
{
    dedata = JSON.parse( CryptoJS.AES.decrypt(decodeURIComponent( urlParams.get('data') ,"base64"), "96358214256984120").toString(CryptoJS.enc.Utf8))
  
    let rd1 = localStorage.getItem('reg_data') || '{}'
    let rd2 = JSON.parse(rd1)
    let emailid=rd2.emailid
let mobileno=rd2.mobileno
let citizen=rd2.citizen
this.setState({citizen:rd2.citizen})
let aadharno=rd2.aadharno

let obj={}
                    let maindata=JSON.parse( rd1)
                    for (var key in maindata) {
                
                let f=0
                if(key==="stage")
                {
                    obj[key]=0
                    f=1
                }
                //added to store digilocker selection to localstorage
            if(key==="aadharupload")
            {
                obj[key]=dedata.panverfy
                    f=1
            }

                
                if(f===0)
                 {
                        obj[key]=maindata[key]
                }
                
                    }
                
                    localStorage.removeItem('reg_data')
                    localStorage.setItem('reg_data', JSON.stringify(obj))
                    if(dedata.panverfy==="1")
                    {
                      
                    
                    window.ChangePageNo (11.1)
                    }
                    if(dedata.panverfy==="0")
                    {
                      
                    
                    window.ChangePageNo (13)
                    }
                    //show the upload screen dependoin on digilocker selection
                    if( dedata.panverfy==="2" || dedata.panverfy==="4")
                    {
                      
                    
                    window.ChangePageNo (13.1)
                    }
                }
                //id central 
/*let payload = {
     
         
    "emailid": emailid,
    "mobileno": mobileno,
    "aadhar":aadharno.substring(8,12)
}
this.setState({islodding:true})
try {
    const digilock = await httpRequest({
        endPoint: `/DigiLocker/getDOC`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload,
    });

    if(digilock.status===201)
    {
        this.setState({ showerr: true, errorMsg:digilock.message, mysnack: snackeror.myerror })
    }
    this.setState({islodding:false})

    if(digilock.status===200)
    {
       
        if(digilock.panfound===1)
        {
      
        window.ChangePageNo (11)
        }
        if(digilock.panfound===0)
        {
      
        window.ChangePageNo (13)
        }
    }
} catch (error) {
    
}*/

}
    }
    Continue = async () => {
        //   window.ChangePageNo (9)
           let rd1 = localStorage.getItem('reg_data') || '{}'
           let rd2 = JSON.parse(rd1)
           let emailid=rd2.emailid
   let mobileno=rd2.mobileno
   let aadharno=rd2.aadharno
   let obj={}
   let maindata=JSON.parse( rd1)

   for (var key in maindata) {

let f=0
if(key==="stage")
{
   obj[key]=3
   f=1
}
if(f===0)
{
      obj[key]=maindata[key]
}

   }

   localStorage.removeItem('reg_data')
   localStorage.setItem('reg_data', JSON.stringify(obj))
        
          
     
     let payload = {
     
         
         "emailid": emailid,
         "mobileno": mobileno,
         "aadhar":aadharno.substring(8,12),
         "type":0
     }
     this.setState({islodding:true})
     try {
         const digilock = await httpRequest({
             endPoint: `/setu/DigiLocker/getTocken`,
             method: "post",
             instance: "instanceTwo",
             requestBody: payload,
         });
     
         
         if(digilock.status===200)
         {
            this.setState({islodding:false})


            let obj={}
            let maindata=JSON.parse( rd1)
            for (var key in maindata) {
        
        let f=0
        if(key==="stage")
        {
            obj[key]=3
            f=1
        }
        if(f===0)
        {
               obj[key]=maindata[key]
       }
        
            }
        
            localStorage.removeItem('reg_data')
            localStorage.setItem('reg_data', JSON.stringify(obj))

             window.open(digilock.url,'_self')
         }
     } catch (error) {
         
     }                                                                                   
     
         }
  render() {
    const { classes } = this.props;
    return (
   
      <div> <Grid className={classes.cong_card}>
        <Grid className={classes.cong_card1}>

            <Typography className={classes.texthear}>Digilocker - Documents for KYC</Typography>
            <Typography className={classes.texthear1}>Digilocker automatically verifies your documents needed for KYC and account opening on BullForce</Typography>
            <Grid style={{width:'100%',display:'flex',justifyContent:'center',marginBottom:'4%'}}>
            <img src={digi} alt='diglocker'></img>
            </Grid>
            <Typography className={classes.texthear}>BullForce uses digilocker to fetch your documents</Typography>
            <Typography className={classes.texthear2}>I provide my consent to share my Aadhaar Number, Date of Birth and Name from my Aadhaar eKYC information with the Income Tax Departmert, Al States for the purpose of fetching my PAN Verification Record into Dig Locker.</Typography>
            <Grid style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
            <img src={Secure} alt='diglocker'></img>
            <Typography className={classes.texthear3}> Your data is safe and secured with us</Typography>
            </Grid>
           {this.state.citizen===0 && 
            <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Grid className={classes.butt_card}>
        <Button fullWidth className={classes.joinDiplomtsBtn}
       style={{ marginTop: '5px', }}
       onClick={() => this.Continue()}
   >Agree & Proceed For KYC

   </Button>
   </Grid>
   </Grid>
   }
           {this.state.citizen===1 && 
           <div>
            <Grid style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>
            <Grid className={classes.butt_card}>
        <Button fullWidth className={classes.joinDiplomtsBtn}
       style={{ marginTop: '5px', }}
       onClick={() => this.Continue()}
   >Agree & Proceed For KYC

   </Button>

   </Grid>
  
   
   </Grid>
     <Grid style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>
     <Grid className={classes.butt_card}>
 <Typography  className={classes.esign_tit1}
style={{ marginTop: '15px', }}
onClick={() => window.ChangePageNo(13.1)}
>Skip

</Typography>

</Grid>


</Grid>
</div>
   }
   
   </Grid>
   </Grid>
   <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
      </div>
    )
  }
}
export default (withStyles(styles)((digilocker)));