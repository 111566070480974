import React, { Component } from "react";
import { withStyles } from "@mui/styles";
import stepconmp3 from "../../../ui-assets/step_con.mp3";
import cong_box from "../../../ui-assets/Congratulation_Box.png";
import ani_gif from "../../../ui-assets/gif_particles.gif";

// File added by dev-trupti on 12-11-2024
import { Typography, Grid, Button, Modal, Box ,Zoom} from "@mui/material";
const snackeror = {
  myerror: {
    backgroundColor: "#ffffff",
    borderLeft: "8px solid #b60000",
    flexWrap: "nowrap",
    color: "#005100",
    borderTop: "1px solid #b60000",
    borderRight: "1px solid #b60000",
    borderBottom: "1px solid #b60000",
  },
  mysucc: {
    backgroundColor: "#ffffff",
    borderLeft: "8px solid #8db600",
    flexWrap: "nowrap",
    color: "#005100",
    borderTop: "1px solid #8db600",
    borderRight: "1px solid #8db600",
    borderBottom: "1px solid #8db600",
  },
  formcontrol_width: {
    width: "100%",
    "@media only screen and (max-device-width: 480px)": {
      width: "90%",
      marginTop: "13%",
    },
  },
  webcamdesk: {
    display: "flex",
    justifyContent: "center",
    "@media only screen and (max-device-width: 480px)": {
      display: "none",
    },
  },
  webcammob: {
    display: "none",
    "@media only screen and (max-device-width: 480px)": {
      display: "flex",
      justifyContent: "center",
    },
  },
  sigCanvas: {
    background: "#ffffff",
  },
  shutter: {
    marginTop: "50px",
    width: "100%",
    height: "100vh",
    background: "red",
    borderTopLeftRadius: "8px",
    borderTopRightRaduis: "8px",
  },
};
const styles = (theme) => ({
  page1card: {
    width: "100%",
    paddingLeft: "20%",
    background: "#1B2636",
    "@media only screen and (max-device-width: 480px)": {
      width: "100%",
      padding: "90px 16px",
    },
  },
  card_tit: {
    color: "#BCD171",
    fontFamily: "cardiuma-M !important",
    fontSize: "28px !important",

    "@media only screen and (max-device-width: 480px)": {
      fontSize: "24px !important",
    },
  },
  errtxt: {
    color: "#F74542 !important",
    fontFamily: "cardiuma-regular !important",
    fontSize: "12px !important",
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
      WebkitTextFillColor: "#ffffff",
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },

  cong_card: {
    position: "absolute",
    top: "45%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "auto",

    outline: "none",
    p: 4,
    "@media only screen and (max-device-width: 480px)": {
      top: "15%",
      left: "0%",
      transform: "none",
    },
  },
  cong_tit1: {
    textAlign: "center",
    color: "#ffffff",
    fontFamily: "cardiuma-Bold !important",
    fontSize: "30px !important",
    "@media only screen and (max-device-width: 480px)": {
      fontSize: "30px !important",
    },
  },
  cong_tit2: {
    textAlign: "center",
    color: "#ffffff !important",
    fontFamily: "cardiuma-regular !important",
    fontSize: "16px !important",
    // width: "50%",
    width:'318px',
    fontWeight: "400",
    "@media only screen and (max-device-width: 480px)": {
      fontSize: "16px !important",
      marginTop: "10%",
      padding: "0px 30px 0px 30px",
      width:'318px',
    //   width: "70%",
    },
  },
  cong_card1: {
    marginTop: "2%",
    width: "100%",
    display: "flex",
    justifyContent: "center",

    "@media only screen and (max-device-width: 480px)": {
      display: "none",
    },
  },
  cong_card2: {
    display: "none",
    "@media only screen and (max-device-width: 480px)": {
      marginTop: "2%",
      width: "100%",
      display: "inline",
    },
  },

  cong_card3: {
    width: "100%",
    marginTop: "1%",
    display: "flex",
    justifyContent: "center",
    "@media only screen and (max-device-width: 480px)": {
      width: "100%",
      marginTop: "3%",
    },
  },
  joinDiplomtsBtn: {
    background: "#F74542 !important",
    padding: "12px 45px !important",
    borderRadius: "20px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-M !important",
  },
  imageStyle:{
    position: "relative !important", 
    // width:'308px !important', 
    // height:'184px !important',
    height:'200px !important',
    "@media only screen and (max-device-width: 540px)": {
      height:'150px !important',
    },
  },
  freeMsg:{
    fontFamily: "cardiuma-Bold !important",
    fontSize:'28px !important',
    textAlign:'center !important',
    position: "absolute !important", 
    padding: '30px 20px 0px 20px',
    top: '0' ,
    background: 'linear-gradient(90deg, #FFEF16 6.09%, #CE7500 94.63%)',
    '-webkit-background-clip': 'text',
    color: 'transparent',
    "@media only screen and (max-device-width: 540px)": {
        padding: '35px 20px 0px 20px',
        fontSize:'20px !important',
        top : "80px"
    },
    "@media only screen and (max-device-width: 400px)": {
        top : "90px",
        fontSize:'5vw !important',
    },
    "@media only screen and (max-device-width: 362px)": {
        top : "100px",
        fontSize:'16px !important',
    },
    "@media only screen and (max-device-width: 332px)": {
        top : "90px",
    },
  }
});
const beforeUnloadHandler = (event) => {
  // Recommended
  window.open("/");
  event.preventDefault();
  console.log("Clear navigation history. ");
  // Included for legacy support, e.g. Chrome/Edge < 119
  event.returnValue = true;
};


class congratulations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      audio_stepcon: new Audio(stepconmp3),
      showang: 1,
      showText: 0,
      expiryDate:0
    };
  }

  componentDidMount = async () => {
    let rd1 = localStorage.getItem('reg_data') || '{}'
    let rd2 = JSON.parse(rd1)
    const expirySubscription = rd2.subscription.find((ele) => ele.serivce === "Expert Advice");
    this.setState({expiryDate:expirySubscription.expert_advice_days})
    window.addEventListener("beforeunload", beforeUnloadHandler);
    setTimeout(
      function () {
        this.state.audio_stepcon.play();
      }.bind(this),
      1000
    );
    setTimeout(
      function () {
        this.setState({ showang: 0 });
        this.setState({showText: 1})
      }.bind(this),
      1500
    );
  };

  render() {
    const { classes } = this.props;
    const { expiryDate } = this.state
    return (
      <div className={classes.cong_card}>
        <Grid
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            position: "relative",
            bottom: 0,
            height:'375px',
            alignItems:'end'
          }}
        >
          <img
            className={classes.imageStyle}
            style={{ }}
            src={cong_box}
            alt="congbull"
          ></img>
          {this.state.showang === 1 && (
            <img
              style={{ position: "absolute", top: 0, height:'300px', width:'320px'}}
              src={ani_gif}
              alt="congbull"
            ></img>
          )}
          {this.state.showText === 1 && (
            <Zoom in = {true} >
              <Typography className={classes.freeMsg}>You have unlocked <br/> Free Account <br/> {expiryDate} days of Expert Recommendation</Typography>
            </Zoom>
          )}
        </Grid>
        <Grid style={{ width: "100%" }}>
          <Grid style={{ width: "100%", marginTop: "1%" }}>
            <Typography className={classes.cong_tit1}>
              Congratulations!
            </Typography>
          </Grid>
          <Grid className={classes.cong_card3}>
            <Typography className={classes.cong_tit2}>
              Your account opening is under process. You will be able to invest
              shortly.
            </Typography>
          </Grid>
        </Grid>
      </div>
    );
  }
}
export default withStyles(styles)(congratulations);
