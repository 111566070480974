import React, { Component } from 'react'
import { withStyles, } from '@mui/styles'
import { Typography, Grid, Box, Button } from '@mui/material'

//full file added by dev-trupti 11-11-2024
import logo from '../../ui-assets/appDownloadsLogo.png'
import backendHosts from '../../ui-utils/apiConfig'
import googlePlayStore from '../../ui-assets/googlePlayStore.png'
import appleStore from '../../ui-assets/appleStore.png'

const styles = theme => ({
    mainlayout: {
        marginTop:'0px',  
    },
    mainGrid: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        // top:'10%'
        // height: '100vh',  
    },
    mainLogo: {
        backgroundImage: `url(${logo})`,
        backgroundSize: 'contain',      
        backgroundPosition: 'center',   
        backgroundRepeat: 'no-repeat',
        height:'145px', 
        width:'145px',
        position: 'absolute',             
        top: '28%',                    
        left: '50%',               
        transform: 'translate(-50%, -50%)',
        zIndex:1
    },
    mainBox: {
        marginTop:'200px',
        height: '420px',
        width: "400px !important",
        borderRadius: '11px',
        backgroundColor:'#ffffff',
        position: 'relative',
        "@media only screen and (max-width: 500px)": {
            width: '80% !important',
        },
    },
    boxContent: {
        marginTop:'53px',
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        textAlign:'center !important'

    },
    txtfeild:{
        height:'38px !important'
    },
    Refbox:{
        width:'228px !important',
        borderRadius:'20px !important', 
        backgroundColor:'#F6FFD8 !important',
        borderStyle:'dashed !important', 
        borderColor:'#F3D45E !important', 
        borderWidth:'1px !important',
        display:'flex !important',
    },
    webText : {
        marginBottom:'3% !important',
        fontSize:'14px',
        color:'#0092F3',
        fontFamily: 'cardiuma-Bold !important',
    },
    codeText:{
        width:'100% !important',
        display:'flex !important',
        justifyContent:'center !important',
        alignItems:'center !important',
        textAlign:'center !important'
    },
    copyButton: {
        width:'68px !important',
        backgroundColor:'#F3D45E !important',
        borderRadius:'19px !important',
        padding:'5px 14px 5px 14px !important',
        fontFamily: 'cardiuma-SemiBold !important',
        textTransform:'none !important',
        margin: '1px 1px 1px 0px !important'
    },
    copytext:{
        marginTop:'2.5% !important',
        fontSize: '12px !important',
        fontFamily: 'cardiuma-regular !important',
        color:'#979797 !important'
    },
    refcode:{
        marginTop:'5px !important',
        fontSize: '14px !important',
        fontFamily: 'cardiuma-regular !important',
    },
    amountText:{
        marginTop:'5% !important'
    },
    strikeAmount:{
        textDecoration:'line-through !important',
        fontSize:'12px !important',
        color:'#999999 !important',
        fontFamily: 'cardiuma-regular !important',
    },
    newAmount: {
        textDecoration:'none !important',
        fontSize:'24px !important',
        color:'#A8CD27 !important',
        fontFamily: 'cardiuma-SemiBold !important',
    },
    freeacc:{
        background: 'linear-gradient(90deg, #4E35C9 0%, #A328EA 54.5%, #261A63 100%) !important',
        backgroundClip: 'text !important',
        WebkitBackgroundClip: 'text !important',
        color: 'transparent !important',
        fontSize:'18px !important',
        fontFamily: 'cardiuma-Bold !important',
    },
    expertText :{
        padding:'0px 20px 0px 20px !important',
        fontSize:'12px !important',
        fontFamily: 'cardiuma-SemiBold !important',
        color:'#82858B !important',
    },
    google:{
        marginTop:'5% !important',
    },
    apple:{
        marginTop:'2.5% !important',
        marginBottom: '6% !important'
    },
    store:{
        height:'50px !important',
        width: '172px !important',
    }  
})

class AppDowmloads extends Component {
    constructor(props) {
        super(props)

        this.state = {
          copytext:'Copy',
          code:'MTLFREEACC'
        }
    }

    TextCopy()
    {   
        console.log("clicked")
        navigator.clipboard.writeText(this.state.code);
        this.setState({copytext:'Copied'})
    }
    
    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F3D45E"
    }

    render() {
        const { classes } = this.props;
        const {copytext, code} = this.state

        return (
            <div className={classes.mainlayout}>
               <Box className={classes.mainGrid}>
                    <Box className={classes.mainLogo}>
                    </Box>
                    <Grid className={classes.mainBox}>       
                        <Box className={classes.boxContent}>
                            <Typography className={classes.webText} 
                            onClick={()=>{window.open(`${backendHosts.WEBSITE}`)}}>
                                    https://bullforce.co
                            </Typography>
                            <div className={classes.txtfeild}>
                                <Box className={classes.Refbox}>
                                    <div className={classes.codeText}><Typography className={classes.refcode}>{code}</Typography></div>
                                    <Button className={classes.copyButton}  onClick={() => this.TextCopy()}>
                                            <span style={{ color: 'black' , marginTop:'2px'}}>{copytext}</span> 
                                    </Button>
                                </Box>
                            </div>
                            <Typography className={classes.copytext}>Copy & paste this code for free account</Typography>
                            <Typography  className={classes.amountText}><span className={classes.strikeAmount}>₹499</span> <span className={classes.newAmount}>₹00</span></Typography>
                            <Typography className={classes.freeacc}>Free Account</Typography>
                            <Typography className={classes.expertText}>+3-month free access to our Expert Recommendations </Typography>
                            <Box className={classes.google} onClick={()=> window.open('https://play.google.com/store/apps/details?id=com.bullforce.xts')}><img src={googlePlayStore} className={classes.store}></img></Box>
                            <Box className={classes.apple} onClick={()=> window.open('https://apps.apple.com/in/app/bullforce/id6473841422')}><img src={appleStore} className={classes.store}></img></Box>
                        </Box>
                    </Grid>
               </Box>
            </div>
        )
    }
}
export default (withStyles(styles)((AppDowmloads)));
