import React, { Component } from 'react'
import { withStyles, } from "@mui/styles";
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import { httpRequest } from '../../../ui-utils/api'
import verfiy from '../../../ui-assets/verfiy.svg'
import unverfiy from '../../../ui-assets/unverfiy.svg'
import { inputLabelClasses } from "@mui/material/InputLabel";
import {signup_funcation} from './signup_funcation'
import step5mp3 from '../../../ui-assets/bank_verfy.mp3'
import { Close } from '@mui/icons-material';
import {
    Typography, Grid, Button,Modal,Box,FormHelperText,TextField,FormControl,
    Snackbar,SnackbarContent,IconButton
} from '@mui/material'
import { FileUploader } from "react-drag-drop-files";//added 26/03/24
import Secure from '../../../ui-assets/Secure.svg';//added 26/03/24
import bankupload from '../../../ui-assets/bank_upload.svg';//added 16/07/24
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


};

const styles = theme => ({
    page1card: {
        width: '100%',
     
        background: '#1B2636',
        "@media only screen and (max-device-width: 480px)": {
            width: '100%',
            padding: '90px 16px',

        }
    },
    card_tit: {
        color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '28px !important',  width: '100%',
     

        "@media only screen and (max-device-width: 480px)": {
            fontSize: '24px !important',
        },

    },
    errtxt: {

        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
    },
    errtxt1: {
        color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
    },
    input: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
            WebkitTextFillColor: '#ffffff'

        },
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    },
    joinDiplomtsBtn: {
        background: "#F74542 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    joinDiplomtsBtn_disable: {
        background: "#999999 !important",
        padding: "12px 45px !important",
        borderRadius: "20px !important",
        fontStyle: "normal !important",
        fontWeight: "500 !important",
        fontSize: "14px !important",
        lineHeight: "18px !important",
        color: "#FFFFFF !important",
        textTransform: "initial !important",
        fontFamily: "cardiuma-M !important"
    },
    upload_tit:{
        color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
    },
    upload_tit1:{
        color: '#7A7C7F !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',paddingLeft:'5px'
    },  
    upload_tit2:{
        color: '#BCD171 !important', fontFamily: 'cardiuma-Bold !important', fontSize: '14px !important',
    },
    bankupload_tit:{
        color: '#BCD171 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
    }
})
function handlechange (e)  {
    e.preventDefault();
    
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
 class bank extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            upi_id:'',
            emailid:'',
            accountmask:0,
            bankverifybuttonst: true,
        islodding:false,
        ifsc: '',
        ifscverify_st:2,
        bankverify_st:2,
        upiverify_st:2,
        bankaccno1:'',
        bankaccno:'',
        bankerror:'',
        audiostep5 : new Audio(step5mp3),
        pageno:0,//added 26/03/24 
        filenamedata1:'',//added 26/03/24 
        filename1:'',//added 26/03/24 
        bankerror1:'',//added 31/03/24 
        bankname:'',//added 31/03/24 
        errdisplaytype:'0'//added 23/07/24
        }



    }
    componentDidMount = async () => {

        let rd1 = localStorage.getItem('reg_data') || '{}'
    
    
    
        let rd2 = JSON.parse(rd1)
        if(rd2.skipaa===true)
        {
            this.setState({pageno:1})
        }else{

        setTimeout(
            function() {
                this.state.audiostep5.play()
            }
            .bind(this),
            1000
        );
    }
            
        }
    GetBankAccount1(e) {
     
        if (e.target.value.length <= 18) {
        this.setState({bankerror:'', bankverifybuttonst: true, ifscverfy_st: false, bankaccno1: e.target.value, errtype: 0, errorMsg: "" });
        }
 
}
GetBankAccount(e) {

        if (e.target.value.length <= 18) {

         
         
                this.setState({ bankaccno: e.target.value,bankerror:''})

         

        this.setState({ bankverifybuttonst: true, ifscverfy_st: false,  errtype: 0, errorMsg: "" });
    }
}
Continue4_1(){
    const { upi_id  } = this.state
    let rd1 = localStorage.getItem('reg_data') || '{}'
    
    
    
    let rd2 = JSON.parse(rd1)
    let emailid=rd2.emailid
    signup_funcation(rd1,"upi_id",upi_id)


let obj={}
                    let maindata=JSON.parse( rd1)
                    for (var key in maindata) {
                
                let f=0
                //Skipaa will remain false if the bank verfication is true - dev-trupti on 26-09-2024
                // if(key==="skipaa")
                // {
                //     obj[key]=true
                //     f=1
                // }
                if(key==="upi_id")
                    {
                        obj[key]=upi_id
                        f=1
                    }

                
                if(f===0)
                 {
                        obj[key]=maindata[key]
                }
                
                    }
                
                    localStorage.removeItem('reg_data')
                    localStorage.setItem('reg_data', JSON.stringify(obj))


    if(emailid==="yogitasal@yahoo.co.in")
    {
        window.ChangePageNo(16)
                   
    }else{
    window.ChangePageNo (8)
    }
}
closeContactModal() {
    this.setState({ showerr: false })
}
Continue4 = async () => {
    const { ifsc, bankaccno, bankaccno1, upi_id,  } = this.state

    this.setState({ ifscverify_st: 2, bankverify_st: 2, upiverify_st: 2, bankverifybuttonst: true })
    let isok = 0
    if (!ifsc.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 5, errorMsg: "Enter the IFSC Code" })


    }
    if (!bankaccno.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 6, errorMsg: "Enter the Bank Account Number" })


    }

    if (!bankaccno1.length && isok === 0) {

        isok = 1
        this.setState({ errtype: 7, errorMsg: "Enter the Confirm bank account number" })


    }
    if (bankaccno !== bankaccno1 && isok === 0) {

        isok = 1
        this.setState({ errtype: 7, errorMsg: "Bank Account Number and Confirm Bank Account Number should be same" })


    }

    if (isok === 0) {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let fullname=rd2.fullname
       let citizen=rd2.citizen// added on 27/08/24 To skip name matching For NRI 
let mobileno=rd2.mobileno
        let payload = {
            "accno": bankaccno,
            "ifsc": ifsc,
            "upi_id": upi_id,
"fullname":fullname,
            "emailid": emailid,
            "mobileno": mobileno


        }
        let payload1 = {


            "upi_id": upi_id,
            "emailid": emailid,
            "mobileno": mobileno
        }
        this.setState({ islodding: true })
//Add it to skip name matching in NRI. 
       let bankendpoint=`/api/verfy/Bank`
      

        try {
            const otp = await httpRequest({
                endPoint: bankendpoint,
                method: "post",
                instance: "instanceTwo",
                requestBody: payload,
            });
            this.setState({ islodding: false })
            

           
          
            if (otp === "API_ERROR") {
                this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })
            }
       
            if (otp.status === 200) {

                if (otp.bankverify === false) {
                    if (otp.response_code !== 1)

                        this.setState({ bankverifybuttonst: true, errtype: 5, errorMsg: otp.message })
                }
                if (otp.bankverify === true) {

                    this.setState({ bankverify_st: 1, ifscverify_st: 1 })
                    let obj={}
                    let maindata=JSON.parse( rd1)
                    for (var key in maindata) {
                
                let f=0
               
                if(key==="ifsc")
                {
                    obj[key]=ifsc
                    f=1
                }
                if(key==="bankaccno")
                {
                    obj[key]=bankaccno
                    f=1
                }
                if(key==="upi_id")
                {
                    obj[key]=upi_id
                    f=1
                }
        
            
                if(f===0)
                 {
                        obj[key]=maindata[key]
                }
                
                    }
                
                    localStorage.removeItem('reg_data')
                    localStorage.setItem('reg_data', JSON.stringify(obj))
                    if (upi_id.length > 3) {
                        this.setState({ islodding: true })
                        try {
                            const upiverfu = await httpRequest({
                                endPoint: `/api/verfy/UPI`,
                                method: "post",
                                instance: "instanceTwo",
                                requestBody: payload1,
                            });
                            this.setState({ islodding: false })
                            if (upiverfu.upiverify === true) { this.setState({ upiverify_st: 1 }) } else { this.setState({ upiverify_st: 0, bankverifybuttonst: true }) }

                            if (upiverfu.status === 201 && upiverfu.upiverify === false) {
                                this.setState({ errtype: 8, errorMsg: otp.message })
                            }

                            if (otp.status === 200 && upiverfu.upiverify === true) {
                                this.setState({

                                    bankverifybuttonst: false, ifscverfy_st: true

                                })

                            }
                        } catch (error) {

                        }
                    } else {
                        this.setState({

                            bankverifybuttonst: false, ifscverfy_st: true,

                        })
                    }

                } else { this.setState({ bankverify_st: 0 }) }


                

            }

            if (otp.status === 201) {


                if (otp.bankverify === false) {
                    if (otp.response_code ===-1) {
                        this.setState({ errtype: 5, errorMsg: otp.message })
                        this.setState({ ifscverify_st: 0 })
                    }
                    if (otp.response_code ===10) {
                       

                        let bankname=otp.message.split(':')[1]
                        let bankerror=otp.message.split(':')[0]
                        let bankerror1=bankname.split('is')[1]
                        bankname=bankname.split('is')[0]
                        
                       //added errdisplaytype on 23/07/24  To manage custom message. 
                        this.setState({ errdisplaytype:"0",  bankerror: bankerror,pageno:1,bankerror1:bankerror1,bankname:bankname })
                    }
                    if (otp.response_code === 3 || otp.response_code === 9) {
                        this.setState({ errtype: 5, errorMsg: otp.message })
                        this.setState({ ifscverify_st: 0 })
                    }
                    if (otp.response_code==2) {
                        this.setState({ errtype: 6, errorMsg: otp.message })
                        this.setState({ bankverify_st: 0 })
                    }
                    if (otp.response_code ===11) {
                          //added errdisplaytype on 23/07/24  To manage custom message. 
                        this.setState({ errdisplaytype:"1",  bankerror: otp.message,pageno:1})
                    }
                }
                if (otp.upiverify === false) {
                    this.setState({ errtype: 8, errorMsg: otp.message })
                }

            }
        } catch (error) {

            this.setState({ showerr: true, errorMsg: "oops something went wrong", mysnack: snackeror.myerror })

        }
    }

}

//adde on 26/03/24 to Upload cheque 
Uploadcheque= async () => {
const {filenamedata1,filename1}=this.state
    let rd1 = localStorage.getItem('reg_data') || '{}'
    let rd2 = JSON.parse(rd1)
    let emailid=rd2.emailid
let mobileno=rd2.mobileno
let aadharno=rd2.aadharno

aadharno=aadharno.substring(8,12)
let payload1={
    emailid:emailid,
    mobileno:mobileno,
    refno:aadharno,
    img:filenamedata1,
    filename:filename1// added on 11/09/24 to store uploaded filename
}

try {
    const chequedata = await httpRequest({
        endPoint: `/api/cancelledcheque/Bank`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload1,
    });
    if(chequedata.status===200)
    {
        //If bank verification is false then set Localstorage skipaa as true too - dev-trupti on 26-09-2024
        let rd1 = localStorage.getItem('reg_data') || '{}'
    
    
    
    let rd2 = JSON.parse(rd1)
    let obj={}
                    let maindata=JSON.parse( rd1)
                    for (var key in maindata) {
                
                let f=0
                if(key==="skipaa")
                {
                    obj[key]=true
                    f=1
                }
                if(f===0)
                 {
                        obj[key]=maindata[key]
                }
                
                    }
                
                    localStorage.removeItem('reg_data')
                    localStorage.setItem('reg_data', JSON.stringify(obj))

        this.Continue4_1()
    }
} catch (error) {

    
}
}
SkipAA()
{
    window.ChangePageNo(16.1)
}
handleChangefile1  = async (file) => {

    let isok=0
   
    if(file.type !== "image/png" && file.type !== "image/jpeg" && file.type!=="image/gif") 
    {
        isok=1
        this.setState({ showerr: true, errorMsg: "Invalid File Format (Only jpg,png,gif supported)", mysnack: snackeror.myerror })
    }

    if(file.size>2000000 && isok===0)
    {
        isok=1
        this.setState({ showerr: true, errorMsg: "Invalid File Size (Only 2MB Allowed)", mysnack: snackeror.myerror })
    }

      if(file.name.length>0 && isok===0)
      {
        this.setState({bankverifybuttonst:false})
        const base64 = await convertBase64(file);
    this.setState({filenamedata1:base64,filename1:file.name})
      }
    
};
  render() {
    const { classes } = this.props;
    return (
      <div> 


               {this.state.pageno===1 &&
        <Grid className={classes.page1card} style={{}}>
      <Typography className={classes.card_tit}>Upload bank cancelled cheque</Typography>
      <Grid style={{marginTop:'5%',width:'100%'}}>
        {/* adden on 23/07/24 To show Custom Message.  */}
        {this.state.errdisplaytype==="0" &&
      <Typography className={classes.upload_tit}>{this.state.bankerror}<span className={classes.upload_tit2}>{this.state.bankname}</span>{this.state.bankerror1}, request  you upload the cancelled cheque or Front Face of the PassBook for further verification. with less than 2MB size</Typography>
    }

        {this.state.errdisplaytype==="1" &&
      <Typography className={classes.upload_tit}>Unable to verify your bank account details due to <span className={classes.upload_tit2}>  {" "} {this.state.bankerror}  {" "}</span>{this.state.bankerror1}, request  that upload the cancelled cheque or Front Face of the PassBook for further verification. with less than 2MB size</Typography>
    }
     
   </Grid>
    
      <Grid style={{marginTop:'5%',width:'100%'}}>
      <Typography className={classes.upload_tit}>Copy of cancelled cheque</Typography>
      <FileUploader
          style={{}}
          multiple={false}
          maxSize={'2mb'}
          hoverTitle="rAI"
          label="Upload or drop a file right here"
            handleChange={this.handleChangefile1}
          name="file"
          
          children={
              <Grid style={{ padding: '15px',  width: '100%', background: '#1B2636', borderRadius: '8px', border: '1px dashed #999999' }}>
                 
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                      <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
                     
                  </Grid>
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.filename}</Typography>
                     
                  </Grid>
              </Grid>
          }
      />

      <Grid style={{width:'100%',display:'flex',marginTop:'2px'}}>
          <Grid style={{width:'75%'}}>
          <Typography className={classes.upload_tit1}>{this.state.filename1}</Typography>
          </Grid>
          <Grid style={{width:'30%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
              <img src={Secure} alt="Secure"></img>
          <Typography className={classes.upload_tit1}>Secure</Typography>
              </Grid>
      </Grid>
          
          </Grid>  


          <Grid style={{width:'100%',display:'flex',marginTop:'15px',justifyContent:'center'}}>
            <Grid style={{width:'90%',background:'#265F0B33',borderRadius:'8px',display:'grid',justifyContent:'center',padding:'10px'}}>
            <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <img src={bankupload} alt="bankupload"></img>
            </div>
            <div>
            <Typography  className={classes.bankupload_tit} style={{textAlign:'center'}}>Due to manual upload, we wouldn’t 
be able to fetch your 6 months ‘statement. 
We will reach out to you soon, Please continue</Typography>
</div>
            </Grid>

          </Grid>
          <Grid style={{width:'100%',display:'flex',marginTop:'25px',justifyContent:'center'}}>
          <Grid style={{ width: '90%' }}>
              <Button

                  disabled={this.state.bankverifybuttonst}
                  fullWidth className={this.state.bankverifybuttonst ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}

                  onClick={() => this.Uploadcheque()}
              >Continue

              </Button>
          </Grid>
          </Grid>
  </Grid>
  }   
        {this.state.pageno===0 &&
        <Grid className={classes.page1card} style={{}}>
      <Typography className={classes.card_tit}>Link bank account</Typography>
      <Typography style={{ color: '#ffffff', fontFamily: 'cardiuma-regular', fontSize: '14px', marginTop: '1%' }}>Bank account in your name from which you will transact funds for trading.</Typography>
      <Grid  >
          <Grid style={{ width: '100%', display: 'flex', alignItems: 'center', marginTop: '20px' }}>
              <Grid style={{ width: '90%' }}>
                  <FormControl style={{ width: '100%' }} variant="standard">
                      <TextField size="small" variant="standard"

                          fullWidth
                          sx={{
                              '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                              '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                              '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                          }}
                          
   InputLabelProps={{
    sx: {
      // set the color of the label when not shrinked
      color: "#FFFFFF",
      fontFamily: 'cardiuma-regular', fontSize: '14px',
      [`&.${inputLabelClasses.shrink}`]: {
        // set the color of the label when shrinked (usually when the TextField is focused)
        color: "#FFFFFF"
      }
    }
}}
                          label='Link using IFSC'
                          value={this.state.ifsc}

                          InputProps={{
                              classes: { input: classes.input },
                              maxLength: 10,
                              style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                          }}
                          onChange={(e) => this.setState({ bankverifybuttonst: true, ifsc: e.target.value, errtype: 0,bankerror:'', errorMsg: "", ifscverfy_st: false })}
                          onFocus={(e) => this.setState({accountmask:1})}
                      ></TextField>
                      {this.state.errtype === 5 &&
                          <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                      }

                  </FormControl>
              </Grid>
              {this.state.ifscverify_st !== 2 &&
                  <Grid style={{ width: '10%', marginLeft: '5px', marginTop: '10px' }}>
                      <img alt='bank' style={{ width: '90%', height: '90%' }} src={this.state.ifscverify_st === 1 ? verfiy : this.state.ifscverify_st === 0 ? unverfiy : ''}></img>
                  </Grid>
              }
          </Grid>
          <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Grid style={{ width: '90%', marginTop: '15px' }}>
                  <FormControl style={{ width: '100%' }} variant="standard">
                      <TextField size="small" variant="standard"
                      type={this.state.accountmask===0 ? 'number':'password'}
                          fullWidth
                          sx={{
                              '& .MuiInput-input': {
                                  '-moz-appearance': 'textfield',
                                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                    '-webkit-appearance': 'none',
                                  }},
                              '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                              '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                              '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                          }}
                           
   InputLabelProps={{
    sx: {
      // set the color of the label when not shrinked
      color: "#FFFFFF",
      fontFamily: 'cardiuma-regular', fontSize: '14px',
      [`&.${inputLabelClasses.shrink}`]: {
        // set the color of the label when shrinked (usually when the TextField is focused)
        color: "#FFFFFF"
      }
    }
}}
                          label='Bank account number'
                          value={this.state.bankaccno}
                        
                          InputProps={{
                              classes: { input: classes.input },
                              maxLength: 12,
                              style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                          }}
                          onChange={(e) => this.GetBankAccount(e)}
                          onFocus={(e) => this.setState({accountmask:0})}
                          
                      ></TextField>
                      {this.state.errtype === 6 &&
                          <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                      }

                  </FormControl>
              </Grid>
              {this.state.bankverify_st !== 2 &&
                  <Grid style={{ width: '10%', marginLeft: '5px', marginTop: '10px' }}>
                      <img alt='bank' style={{ width: '90%', height: '90%' }} src={this.state.bankverify_st === 1 ? verfiy : this.state.bankverify_st === 0 ? unverfiy : ''}></img>
                  </Grid>
              }
          </Grid>

          <Grid style={{ width: '90%', marginTop: '15px' }}>
              <FormControl style={{ width: '100%' }} variant="standard">
                  <TextField size="small" variant="standard"
                    type='number'
                      fullWidth
                      sx={{
                          '& .MuiInput-input': {
                              '-moz-appearance': 'textfield',
                              '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
                                '-webkit-appearance': 'none',
                              }},
                          '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                          '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                          '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                          '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                      }}
                      
   InputLabelProps={{
    sx: {
      // set the color of the label when not shrinked
      color: "#FFFFFF",
      fontFamily: 'cardiuma-regular', fontSize: '14px',
      [`&.${inputLabelClasses.shrink}`]: {
        // set the color of the label when shrinked (usually when the TextField is focused)
        color: "#FFFFFF"
      }
    }
}}

                      label='Confirm bank account number'

                      value={this.state.bankaccno1}
                      InputProps={{

                          classes: { input: classes.input },
                          style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                      }}
                      onChange={(e) => this.GetBankAccount1(e)}
                      onFocus={(e) => this.setState({accountmask:1})}
                      onCut={(e) => handlechange(e)}
                      onCopy={(e) => handlechange(e)}
                      onPaste={(e) => handlechange(e)}
                  ></TextField>
                  {this.state.errtype === 7 &&
                      <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                  }

              </FormControl>
          </Grid>

          <Grid style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
              <Grid style={{ width: '90%', marginTop: '15px' }}>
                  <FormControl style={{ width: '100%' }} variant="standard">
                      <TextField size="small" variant="standard"
                          fullWidth
                          sx={{
                              '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
                              '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
                              '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
                              '& .MuiInputLabel-root.Mui-focused':{color:'#BCD171'}
                          }}
                          
   InputLabelProps={{
    sx: {
      // set the color of the label when not shrinked
      color: "#FFFFFF",
      fontFamily: 'cardiuma-regular', fontSize: '14px',
      [`&.${inputLabelClasses.shrink}`]: {
        // set the color of the label when shrinked (usually when the TextField is focused)
        color: "#FFFFFF"
      }
    }
}}
                          label='Virtual payment (UPI ID)'


                          value={this.state.upi_id}
                          InputProps={{

                              classes: { input: classes.input },
                              style: { color: "#FFFFFF", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
                          }}
                          onChange={(e) => this.setState({ bankverifybuttonst: true, upi_id: e.target.value, errtype: 0, errorMsg: "", ifscverfy_st: false })}
                          onFocus={(e) => this.setState({accountmask:1})}
                      ></TextField>
                      {this.state.errtype === 8 &&
                          <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
                      }

                  </FormControl>

              </Grid>

              {this.state.upiverify_st !== 2 &&
                  <Grid style={{ width: '10%', marginLeft: '5px', marginTop: '10px' }}>
                      <img style={{ width: '90%', height: '90%' }} alt="upi" src={this.state.upiverify_st === 1 ? verfiy : this.state.upiverify_st === 0 ? unverfiy : ''}></img>
                  </Grid>
              }


          </Grid>
          <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '5px' }}>
            <Typography className={classes.errtxt1}>{this.state.bankerror}</Typography>
            </Grid>
          <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '35px' }}>
              <Button

                  disabled={this.state.ifscverfy_st}
                  fullWidth
                  className={this.state.ifscverfy_st ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}


                  onClick={() => this.Continue4()}
              >Verify

              </Button>
          </Grid>
          <Grid style={{ width: '90%', marginLeft: '5px', marginTop: '25px' }}>
              <Button

                  disabled={this.state.bankverifybuttonst}
                  fullWidth className={this.state.bankverifybuttonst ? classes.joinDiplomtsBtn_disable : classes.joinDiplomtsBtn}

                  onClick={() => this.Continue4_1()}
              >Continue

              </Button>
          </Grid>
      </Grid>

  </Grid>
  }
  <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
  </div>
    )
  }
}
export default (withStyles(styles)((bank)));