import React, { Component } from 'react'
import { FileUploader } from "react-drag-drop-files";

import { withStyles, styled} from "@mui/styles";

import { httpRequest } from '../../../ui-utils/api'
import Loader from "react-js-loader";
import bull from '../../../ui-assets/bull.svg'
import Secure from '../../../ui-assets/Secure.svg'
import psi_img from '../../../ui-assets/psi_img.svg' //added on 20/08/24 
import { Close } from '@mui/icons-material';
import {
    Typography, Grid, Button,Modal,Box, Snackbar, SnackbarContent, IconButton,RadioGroup,FormControlLabel,Radio, 
    FormControl,InputLabel,Select,MenuItem,FormHelperText,TextField,Checkbox
} from '@mui/material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import bankupload from '../../../ui-assets/bank_upload.svg';//added 16/07/24
/*const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));*/
const snackeror = {
   myerror: {
       backgroundColor: "#ffffff",
       borderLeft: "8px solid #b60000",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #b60000",
       borderRight: "1px solid #b60000",
       borderBottom: "1px solid #b60000",
   },
   mysucc: {

       backgroundColor: "#ffffff",
       borderLeft: "8px solid #8db600",
       flexWrap: "nowrap",
       color: "#005100",
       borderTop: "1px solid #8db600",
       borderRight: "1px solid #8db600",
       borderBottom: "1px solid #8db600",
   },
   formcontrol_width:
   {
       width: '100%',
       "@media only screen and (max-device-width: 480px)": {
           width: '90%', marginTop: '13%',
       },
   },
   webcamdesk: {
       display: 'flex',
       justifyContent: 'center',
       "@media only screen and (max-device-width: 480px)": {
           display: 'none'

       }
   },
   webcammob: {
       display: 'none',
       "@media only screen and (max-device-width: 480px)": {
           display: 'flex',
           justifyContent: 'center'

       }
   },
   sigCanvas:{
       background:'#ffffff'
   },
   shutter:{
       marginTop:'50px',
       width:'100%',
       height:'100vh',
       background:'red',
       borderTopLeftRadius :'8px',
       borderTopRightRaduis:'8px',
   },


 
};
const WhiteBackgroundCheckbox = withStyles(theme => ({
    root: {
        color: "#F74542",
        "& .MuiIconButton-label": {
            position: "relative",
            zIndex: 0
        },
        "&:not($checked) .MuiIconButton-label:after": {
            content: '""',

            height: 10,
            width: 10,
            position: "absolute",
            backgroundcolor: "white",
            zIndex: -1
        },
        '&$checked': {
            color: '#F74542'
        }
    },
    checked: { color: "#F74542", }
}))(Checkbox);
const styles = theme => ({
  

   
   errtxt: {
       color: '#F74542 !important', fontFamily: 'cardiuma-regular !important', fontSize: '12px !important',
   },
   input: {
       "&:-webkit-autofill": {
           WebkitBoxShadow: "0 0 0 1000px #1B2636 inset",
           WebkitTextFillColor: '#ffffff'

       },
       '& input[type=number]': {
           '-moz-appearance': 'textfield'
       },
       '& input[type=number]::-webkit-outer-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       },
       '& input[type=number]::-webkit-inner-spin-button': {
           '-webkit-appearance': 'none',
           margin: 0
       }
   },



   joinDiplomtsBtn: {
       background: "#F74542 !important",
       padding: "12px 45px !important",
       borderRadius: "20px !important",
       fontStyle: "normal !important",
       fontWeight: "500 !important",
       fontSize: "14px !important",
       lineHeight: "18px !important",
       color: "#FFFFFF !important",
       textTransform: "initial !important",
       fontFamily: "cardiuma-M !important"
   },
   page2card_2: {
    width: '100%',
    
    "@media only screen and (max-device-width: 480px)": {
        width: '100%',
        padding: '100px 16px',
        marginLeft: '0%',

    }
},
card_tit: {
    color: '#BCD171', fontFamily: 'cardiuma-M !important', fontSize: '24px !important',

    "@media only screen and (max-device-width: 480px)": {
        fontSize: '24px !important',
    },

},
upload_tit:{
    color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
},

upload_tit4:{
    color: '#1B2636 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
},

upload_tit1:{
    color: '#7A7C7F !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',paddingLeft:'5px'
},
//added 21/03/24
joinDiplomtsBtn_disable: {
    background: "#999999 !important",
    padding: "12px 45px !important",
    borderRadius: "20px !important",
    fontStyle: "normal !important",
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "18px !important",
    color: "#FFFFFF !important",
    textTransform: "initial !important",
    fontFamily: "cardiuma-M !important"
},
bankupload_tit:{
    color: '#BCD171 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
},

//added on 20/08/24
psi_tit:{
    color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '18px !important',
    "@media only screen and (max-device-width: 480px)": {
        fontSize: '18px !important',
    },
},
tooltip_tit:
{
    color: '#0B1420 !important', fontFamily: 'cardiuma-regular !important', fontSize: '14px !important',
    textAlign:'center'
},

no_tit:
{
    color: '#FFFFFF !important', fontFamily: 'cardiuma-regular !important', fontSize: '20px !important',
     textAlign:'center'
}
})
const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
  
class psi extends Component {
    constructor(props) {
        super(props)

       
        this.state = {
            mysnack: snackeror.mysucc,
            showerr: false,
            errorMsg: '0',
            mobileno: '',
            errtype: 0,
            countryname:'',
     
            filename3:'',
         
            filenamedata3:'',
            islodding:false,
            psi:1,
            psino:"",
            psivalue:'Yes',
            tandcond:true,//added 21/03/24
            condbut:false,
            showtooltip:false,
            nre:true,
            nro:false,
            nrivalue:'Yes'

        }



    }
  

handleChangefile3  = async (file) => {
    let fsize=(file.size / (1024*1024)).toFixed(2);
    let isok=0

    if(file.type !== "application/pdf" && file.type !== "image/png" && file.type !== "image/jpeg" && file.type!=="image/gif") 
    {
        isok=1
        this.setState({ showerr: true, errorMsg: "Invalid File Format (Only jpg,png,gif supported)", mysnack: snackeror.myerror })
    }


    if(fsize>2)
    {
        isok=1
        this.setState({ showerr: true, errorMsg: "Max 2MB allowed", mysnack: snackeror.myerror })
    }
    if(fsize<2 &&  isok===0)
    {
        const base64 = await convertBase64(file);
        this.setState({filenamedata3:base64,filename3:file.name})
    }
    };
    closeContactModal() {
        this.setState({ showerr: false })
    }
    UploadDoc = async () => {
        let rd1 = localStorage.getItem('reg_data') || '{}'
        let rd2 = JSON.parse(rd1)
        let emailid=rd2.emailid
let mobileno=rd2.mobileno
let id=rd2.id
        const { psi,psino,psivalue,filename3,filenamedata3,nre,nro}=this.state
let isok=0



if(psi===0)
{
    window.ChangePageNo (8)
}
if(psi===1)
{

    if (!psino.length && isok === 0) {
            
        isok = 1
       this.setState({ errtype: 7, errorMsg: "Enter the PSI Number"})


    }

        if (!filename3.length && isok === 0) {
            
            isok = 1
           this.setState({ showerr: true, errorMsg: "Copy of RBI permission / approval ( portfolio investment scheme)", mysnack: snackeror.myerror })


        }
    }

        if(isok===0 && psi===1)
        {

            

            let payload3={
                emailid:emailid,
                mobileno:mobileno,
                type:5,
                data:filenamedata3,
                id:id,
                filename:filename3
            }
            
            let psipayload={
                emailid:emailid,
                mobileno:mobileno,
                psino:psino,
               
                psivalue:psivalue,
                nre:nre,
                nro:nro
                
            }

this.setState({islodding:true})

try {
    const psidata = await httpRequest({
        endPoint: `/api/nri/psi`,
        method: "post",
        instance: "instanceTwo",
        requestBody: psipayload,
    });
} catch (error) {
    
}

try {
    const fileu1 = await httpRequest({
        endPoint: `/api/nri/upload`,
        method: "post",
        instance: "instanceTwo",
        requestBody: payload3,
    });
    if (fileu1.status === 200) {
        window.ChangePageNo (7)

       




    }
} catch (error) {
    
}

        }
        

    }

    async handlePaymentMode1(e) {

        let psi = e.target.value;

        if(e.target.value==="No")
        {
            this.setState({psi:0})
        }
        if(e.target.value==="Yes")
        {
            this.setState({psi:1})
        }
        if(e.target.value==="No1")
            {
                this.setState({psi:3})
            }
            if(e.target.value==="Yes1")
                {
                    this.setState({psi:4})
                }
        this.setState({ psivalue: e.target.value })

       

    };  
    async handlePaymentMode(e) {

  
        if(e.target.value==="No")
            {
                this.setState({nri:true})
            }
            if(e.target.value==="Yes")
                {
                    this.setState({nro:true})
                }
        this.setState({ nrivalue: e.target.value })

       

    };  
    authorise(){
        this.setState({ tandcond: !this.state.tandcond ,condbut: !this.state.condbut})
        }
        
        handleTooltipClose(){
            this.setState({showtooltip:false})
        }

  render() {
    const { classes } = this.props;

    const {psivalue,nrivalue}=this.state

      
    return (
      <div>    <Grid className={classes.page2card_2}  >
      <Typography className={classes.card_tit}>Link Bank Account
      </Typography>
{/* added nre and nro in psi */}
      <Grid style={{marginTop:'3%',width:'100%',display:'flex',alignItems:'center'}}>
              <Typography className={classes.psi_tit}>Select below option</Typography>
       </Grid>
   
      <Grid style={{marginTop:'1%',width:'100%',display:'flex',alignItems:'center'}}>
              <RadioGroup
              row
              defaultValue="Yes"
              value={nrivalue}

style={{marginLeft:'5%'}}
              onChange={e =>
                  this.handlePaymentMode(e)


              }
          >
              <FormControlLabel value="Yes" control={<Radio
                  disableripple
                
                  sx={{
                      color: "#999999",
                      "&.Mui-checked": {
                          position: "relative",
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              color: "yellow"
                          },
                          "&::after": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#F74542",

                              borderColor: "#ffffff",
                              color: "yellow"
                          },

                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                              stroke: '#ffffff',
                              strokeWidth: 0.5,
                          },
                      }

                  }}
              />} label={<Grid> <Typography className={classes.upload_tit}>NRI</Typography></Grid>}/>
              <FormControlLabel value="No" control={<Radio

                 
                  sx={{
                      color: "#999999",
                      "&.Mui-checked": {
                          position: "relative",
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              color: "yellow"
                          },
                          "&::after": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#F74542",

                              borderColor: "#ffffff",
                              color: "yellow"
                          },

                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                              stroke: '#ffffff',
                              strokeWidth: 0.5,
                          },
                      }

                  }}
              />} label={
              
                <Grid>
             <Typography className={classes.upload_tit}>NRO</Typography>
              </Grid>
              } />
            






          </RadioGroup>
            </Grid>
  
          <Grid style={{marginTop:'3%',width:'100%',display:'flex',alignItems:'center'}}>
              <Typography className={classes.psi_tit}>Do you have PIS Account?</Typography>
              <ClickAwayListener onClickAway={(e)=>this.handleTooltipClose()}>  
              <Tooltip  
              open={this.state.showtooltip}
              TransitionComponent={Zoom}
              
              onClose={(e) => this.handleTooltipClose()}
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'common.white',
                    '& .MuiTooltip-arrow': {
                      color: 'common.white',
                    },
                  },
                },
              }}
              title={
          <React.Fragment>
            <Typography className={classes.tooltip_tit}>With the PIS (Portfolio Investment Scheme), you can purchase and sell shares and debentures of Indian companies on a recognized stock exchange under repatriation or non-repatriation basis.</Typography>
           
          </React.Fragment>
        } arrow placement="bottom-end">
              <img style={{marginLeft: window.innerWidth<600?'50px':'10px'}} src={psi_img}
              
              onClick={(e) => this.setState({showtooltip:true})}
              ></img>
    </Tooltip>
    </ClickAwayListener>
              </Grid>
              <Grid style={{marginTop:'1%',width:'100%',display:'flex',alignItems:'center'}}>
              <RadioGroup
              row
              defaultValue="Yes"
              value={psivalue}

style={{marginLeft:'5%'}}
              onChange={e =>
                  this.handlePaymentMode1(e)


              }
          >
              <FormControlLabel value="Yes" control={<Radio
                  disableripple
                
                  sx={{
                      color: "#999999",
                      "&.Mui-checked": {
                          position: "relative",
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              color: "yellow"
                          },
                          "&::after": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#F74542",

                              borderColor: "#ffffff",
                              color: "yellow"
                          },

                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                              stroke: '#ffffff',
                              strokeWidth: 0.5,
                          },
                      }

                  }}
              />} label={<Grid> <Typography className={classes.upload_tit}>Yes</Typography></Grid>}/>
              <FormControlLabel value="No" control={<Radio

                 
                  sx={{
                      color: "#999999",
                      "&.Mui-checked": {
                          position: "relative",
                          "&::before": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              color: "yellow"
                          },
                          "&::after": {
                              content: '""',
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: "10px",
                              height: "10px",
                              borderRadius: "50%",
                              backgroundColor: "#F74542",

                              borderColor: "#ffffff",
                              color: "yellow"
                          },

                          '& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path':
                          {
                              stroke: '#ffffff',
                              strokeWidth: 0.5,
                          },
                      }

                  }}
              />} label={
              
                <Grid>
             <Typography className={classes.upload_tit}>No</Typography>
              </Grid>
              } />
            






          </RadioGroup>
            </Grid>
     
            {this.state.psi===0 && 
            <Grid style={{background:'#1B2F2E',borderRadius:'4px',padding:'20px 50px'}}>
              <Typography className={classes.no_tit}>Operations team will contact with you to create a PIS account</Typography>
              </Grid>
              
  }

{this.state.psi===1 && 
<div>
 <Grid style={{marginTop:'5%',width:'100%'}}>
 <FormControl style={{ width: '100%' }} variant="standard">

 <TextField size="small" variant="standard"
     fullWidth
     type='number'
     sx={{
         '& .MuiInput-input': {
             '-moz-appearance': 'textfield',
             '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
               '-webkit-appearance': 'none',
             }},
         '& .MuiInput-underline:before': { borderBottomColor: '#FFFFFF' },
         '& .MuiInput-underline:hover:before': { borderBottomColor: '#BCD171' },
         '& .MuiInput-underline:after': { borderBottomColor: '#BCD171' },
     }}
     InputLabelProps={{
         style: { color: this.state.errtype === 7 ? "#F74542" : '#BCD171', fontFamily: 'cardiuma-regular', fontSize: '12px' },
     }}
     label='PIS Number'

     value={this.state.nominee_aadhaar}
     InputProps={{

         classes: { input: classes.input },
         style: { color: "#ffffff", fontFamily: 'cardiuma-regular', fontSize: '18px', height: '30px' }
     }}
     onChange={(e) => this.setState({psino:e.target.value,errtype:0})}
 ></TextField>
 {this.state.errtype === 7 &&
     <FormHelperText className={classes.errtxt} id="component-error-text">{this.state.errorMsg}</FormHelperText>
 }
</FormControl>
</Grid>
          <Grid style={{marginTop:'3%',width:'100%'}}>

      <Typography className={classes.upload_tit}>Copy of RBI permission / approval ( portfolio investment scheme )</Typography>
    
      <FileUploader
          style={{}}
          multiple={false}
          maxSize={'1mb'}
          hoverTitle="rAI"
          label="Upload or drop a file right here"

          //   onChange={(e) => this.handleChange(e)}
          handleChange={this.handleChangefile3}
          name="file"
          
          children={
              <Grid style={{marginTop:'10px', padding: '15px',  width: '100%', background: '#1B2636', borderRadius: '8px', border: '1px dashed #999999' }}>
                 
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>Drag and Drop here/</Typography>
                      <Typography style={{ color: '#42A0F7', fontFamily: 'cardiuma-regular !important', fontSize: '14px',cursor:'pointer' }}>Browse File</Typography>
                     
                  </Grid>
                  <Grid style={{ width: '100%',display:'flex',justifyContent:'center' }}>
                      
                      <Typography style={{ color: '#666666', fontFamily: 'cardiuma-regular !important', fontSize: '14px' }}>{this.state.filename}</Typography>
                     
                  </Grid>
              </Grid>
          }
      />

      <Grid style={{width:'100%',display:'flex',marginTop:'2px'}}>
          <Grid style={{width:'75%'}}>
          <Typography className={classes.upload_tit1}>{this.state.filename3}</Typography>
          </Grid>
          <Grid style={{width:'30%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
              <img src={Secure} alt="Secure"></img>
          <Typography className={classes.upload_tit1}>Secure</Typography>
              </Grid>
      </Grid>
          </Grid>
          </div>
  }

    
          <Grid style={{width:'100%',display:'flex',justifyContent:'space-evenly'}}>


         

          <Grid style={{width:'100%',display:'flex',justifyContent:'center'}}>
<Grid style={{width:'80%'}}>
<Button fullWidth 
disabled={this.state.condbut}
className={this.state.condbut?classes.joinDiplomtsBtn_disable: classes.joinDiplomtsBtn}

          style={{ marginTop: '15px'}}
          onClick={() => this.UploadDoc()}
      >Continue

      </Button>
      </Grid>
</Grid>

          </Grid>
          </Grid>
          <Typography className={classes.upload_tit4} style={{color:'#1B2636'}}>Copy of RBI permission / approval ( portfolio investment scheme)</Typography>
          <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

</Modal>
<Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                    open={this.state.showerr}
                    autoHideDuration={3000}
                    onClose={() => this.closeContactModal()}

                >

                    <SnackbarContent
                        style={this.state.mysnack}
                        message={

                            <span style={{
                                display: "flex",
                                alignItems: "center",
                                color: "#333333",
                                fontSize: "12px",
                                fontFamily: "cardiuma-M !important"
                            }}>

                                {this.state.errorMsg}

                            </span>
                        }
                        action={
                            <React.Fragment>

                                <IconButton
                                    size="small"
                                    aria-label="close"
                                    color="inherit"
                                    onClick={() => { this.setState({ showerr: false }) }}
                                >
                                    <Close fontSize="small" />
                                </IconButton>
                            </React.Fragment>
                        }
                    >

                    </SnackbarContent>

                </Snackbar>
                <Modal
                    sx={{ border: 'none' }}
                    open={this.state.islodding}
                >

                    <Box
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '20%',
                            height: '8%',

                            outline: 'none',
                            background: 'transparent',
                            p: 4,
                            borderRadius: '12px',

                            justifyContent: 'center', alignItems: 'center'
                        }}
                    >

                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={bull} alt={bull} width='50%' height='50%' />
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <Loader type="bubble-loop" bgColor={"#F3D45E"} color={'#F3D45E'} size={60} />
                        </Grid>
                    </Box>

                </Modal>
</div>
    )
  }
}
export default (withStyles(styles)((psi)));