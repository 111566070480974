import React, { Component } from 'react'
import { withStyles, } from '@mui/styles'
import { Typography, Grid, Hidden } from '@mui/material'

import picon1 from "../../ui-assets/picon1.svg"
import picon2 from "../../ui-assets/picon2.svg"
import picon3 from "../../ui-assets/picon3.svg"
import picon4 from "../../ui-assets/picon4.svg"
import Footer from "../landingpage/fotter"
// meta tag & content added - 02/09/24 - Dev Srini
import DocumentMeta from 'react-document-meta'
    {/* Updated wealth vault price by dev-trupti on 25-10-2024 */}
const meta = {
    title: 'Pricing | BullForce',
    description: 'Start trading with plans from ₹499/year. Get expert SEBI-registered advice for ₹1999. Wealth Vault at ₹4999 for advanced wealth management.',
    canonical: 'https://bullforce.co/pricing',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'demat, pricing, bullforce, plans, NSE, BSE, CDSL, trading, stocks, bonds, mutual funds, gold, IPOs, SEBI, registered, Wealth'
        }
    }
}

const styles = theme => ({
    maintit: {
        fontSize: '10px',
        fontFamily: 'cardiuma-M !important',
        color: 'red !important'
    
    },
    
    mainlayout :{
        padding: '42px 42px 15px 42px',
        display: 'flex',
        justifyContent: 'center',
        "@media screen and (max-width:960px)": {
         width:'100%',padding: '42px 16px 0px 16px',
         

        }
    },

    mainlayout1 :{
        
        display: 'flex',
        justifyContent: 'center',
        "@media screen and (max-width:960px)": {
         width:'100%',padding: '16px',
         

        }
    },
    
    heading: {
        color: '#3E3E3E !important',
        fontFamily: 'cardiuma-Bold !important',
        fontSize: '32px !important',
        fontStyle: 'normal !important',
        fontWeight: '600 !important',
        lineHeight: 'normal !important',
        // h1 tag margin start&end values removed - 02/09/24 - Dev Srini
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
            fontSize: '24px !important',
   
           }
    },
    
    headingsub: {
        padding: '7px !important',
        color: '#3E3E3E !important',
        fontFamily: 'cardiuma-regular !important',
        backgroundColor: '#ffffff !important',
        fontSize: '14px !important',
        fontStyle: 'normal !important',
        borderRadius: '6px !important',
        fontWeight: '400 !important',
        
        display: 'flex',
        alignitems: 'center !important',
        "@media screen and (max-width:960px)": {
            fontSize: '14px !important',
            padding: '5px 10px !important',
           }
    },
    
    playout: {
        padding: '31px 144px !important',
        display:'flex !important',
        justifyContent:'space-evenly',
        width:'100%',
        
        "@media screen and (max-width:960px)": {
            
         
            padding: '16px !important',
            display:'block !important',
            justifyContent:'center !important',

           }
        },
    
    card :{
        backgroundColor:'#FFFFFF !important',
        borderRadius:'8px',
        height:'auto !important',
        width:'22%',
        "@media screen and (max-width:960px)": {
            width:'100%',
            padding:'16px',
            marginTop:'16px'
        }
        
    
    },
    
    card1:{
        backgroundColor:'#ffffff !important',
        borderRadius:'8px !important',
        padding:'14px !important',
        "@media screen and (max-width:960px)": {
            borderRadius:'0px !important',
            
        }
    },
    
    img_back: {
        height:'52px !important' ,
        width:'52px !important',
        borderRadius:'50% !important',
        backgroundColor:'#F7F9FA !important',
        display:'flex',
        justifyContent:'center',
        alignitems:'center',
    
    },
    
    amount:{
    
        color:'#3E3E3E !important',
        padding:'0px 0px 0px 24px',
        fontFamily:'cardiuma-M !important',
        fontSize:'32px !important',
        fontStyle:'normal',
        fontWeight:'500',
        lineHeight:'normal',
    },
    
    cardhead:{
        padding:'0px 0px 0px 24px !important',
        color:'#3E3E3E !important',
        fontFamily:'cardiuma-Bold !important',
        fontSize:'14px !important',
        fontStyle:'normal',
        fontWeight:'700',
        lineHeight:'18px',
    },
    
    cardheadsub:{
        padding:'0px 0px 24px 24px !important',
        color:'#999',
        fontFamily:'cardiuma-Bold !important',
        fontSize:'12px !important',
        fontStyle:'normal',
        fontWeight:'500',
        lineHeight:'18px',
        /* 150% */
    },
    
    subhead: {
        color:'#3E3E3E !important',
        fontFamily:'cardiuma-Bold !important',
        fontSize:'20px !important',
        fontStyle:'normal',
        fontWeight:'600',
        lineHeight:'normal',
        marginBlockStart: 0,
        marginBlockEnd: 0,

        "@media screen and (max-width:960px)": {
         textAlign:'center'
        }
    },
    
    clegend:{
        color:'#3E3E3E',
        fontFamily:'cardiuma-regular !important',
        fontSize:'14px !important',
        fontStyle:'normal',
        fontWeight:'400',
        lineHeight:'normal',
    },
    
    redc:{
        height:'6px !important',
        width:'6px !important',
        borderRadius:'50% !important',
        backgroundColor:'#F74542 !important',
        "@media screen and (max-width:960px)": {
            marginLeft:'5px'
            
        }
    },
    
    greenc: {
        height:'6px',
        width:'6px',
        borderRadius:'50%',
        backgroundColor:'#21B12F',
        "@media screen and (max-width:960px)": {
            marginLeft:'5px'
            
        }
    },
    
    whitec: {
        height:'6px',
        width:'6px',
        borderRradius:'50%',
        backgroundColor:'#fff',
    },
    
    buy:{
        color:'#21B12F',
        fontFamily:'cardiuma-regular !important',
        fontSize:'14px !important',
        fontStyle:'normal',
        fontWeight:'400',
        lineHeight:'normal',
    },
    
    sell: {
        color:'#F74542',
        fontFamily:'cardiuma-regular !important',
        fontSize:'14px !important',
        fontStyle:'normal',
        fontWeight:'400',
        lineHeight:'normal',
    },
    
    tabelcard: {
        backgroundColor:'#fff',
    
    
    
    },
    
    thead: {
    
        color:'#3E3E3E',
        textalign:'center',
        fontFamily:'cardiuma-Bold !important',
        fontSize:'14px !important',
        padding:'14px',
        fontStyle:'normal',
        fontWeight:'700',
        lineHeight:'18px',
    
    
    },
    
    tlayout: {
        width:'50%',
        display:'flex',
    
    
    
    },
    
    tb: {
        color:'#3E3E3E',
        textalign:'center',
        fontFamily:'cardiuma-regular !important',
        fontSize:'14px !important',
    
        fontStyle:'normal',
        fontWeight:'400',
        "@media screen and (max-width:960px)": {
            fontSize:'12px !important',
            padding:'5px 0px 0px 5px',
           }
    
    },
    
    tb1: {
        color:'#3E3E3E',
        textalign:'center',
        fontFamily:'cardiuma-regular !important',
        fontSize:'14px !important',
    
        fontStyle:'normal',
        fontWeight:'400',
    
    
    },
    
    
    Penaltiesh1: {
        color:'#000',
        fontFamily:'cardiuma-regular !important',
        fontSize:'14px !important',
        fontStyle:'normal',
        fontWeight:'400',
        lineHeight:'normal',
    
    },
    
    Penaltiesh2: {
        color:'#000',
        fontFamily:'cardiuma-Bold !important',
        fontSize:'14px !important',
        fontStyle:'normal',
        fontWeight:'500',
        lineHeight:'normal',
    },
    
    gst: {
        color:'#000',
        fontFamily:'cardiuma-Bold !important',
        fontSize:'14px !important',
        fontStyle:'normal',
        fontWeight:'500',
        lineHeight:'normal',
    },
    main1:{
        marginTop:'90px',
        "@media screen and (max-width:960px)": {
            marginTop:'40px',

        }
    },
    leg1:{
        width: '100%', padding:'42px 150px 0px 150px', display: 'flex',
        "@media screen and (max-width:960px)": {
            display: 'block',
            justifyContent:'center',
            padding:'0px'

        }
    },

    leg2:{
        width: '75%',
        "@media screen and (max-width:960px)": {
            width: '100%',
        }
    },
    leg3:{
        width: '25%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
        "@media screen and (max-width:960px)": {
            width: '100%',justifyContent: 'center',
        }
    },
    t1:{
        width: '100%', padding: '10px 144px 0px 144px',


        "@media screen and (max-width:960px)": {
            
            padding: '16px !important',
            width: '100%',


           }

    },
    p1:{
        width: '100%', padding: '65px 144px 0px 144px',
        "@media screen and (max-width:960px)": {
            padding:'16px'
        }
    },
    p1a:{
        width: '100%', padding: '65px 144px 70px 144px',
        "@media screen and (max-width:960px)": {
            padding:'16px'
        }
    },
    p2:{
        width: '100%', background: '#ffffff', borderRadius: '4px', padding: '32px',
        "@media screen and (max-width:960px)": {
            padding:'16px'
        }

    },
    p3:{
        width: '50%', padding: '12px 0px' ,
        "@media screen and (max-width:960px)": {
            width: '100%',
            padding:'16px 0px 0px 0px',

        }
    },
    card2:{
        width: '100%', display: 'flex', justifyContent: 'flex-end', padding: '24px',
        "@media screen and (max-width:960px)": {
            width: '100%',
            padding:'0px',

        }
    }
})

class pricing extends Component {
    componentDidMount = async () => {
        //document.body.style.backgroundColor = "#F7F9FA"
        document.body.style.backgroundColor = "#EFEFEF"

      
        window.Changemytab(0)
      
    
    }
    render() {
        const { classes } = this.props;

        return (
            <div className={classes.main1}>
                    <DocumentMeta {...meta} />
                    <Grid className={classes.mainlayout} >
                        {/* mui typography replaced with html h1 tag - 02/09/24 - Dev Srini */}
                        <h1 className={classes.heading}>Pricing</h1>
                    </Grid>

                    <Grid className={classes.mainlayout1}  >
                        <Typography className={classes.headingsub} >Zero Hidden Charges!</Typography>
                    </Grid>
                    
                    <Grid className={classes.playout} >
                        <Grid className={classes.card} >
                            <Grid className={classes.card2} >
                                <div className={classes.img_back}>
                                    <img src={picon1} alt="icon" width='30px'></img>
                                </div>
                            </Grid>
                            <Typography className={classes.amount}>₹499</Typography> 
                            <Typography className={classes.cardhead}>Account opening & AMC</Typography>
                            <Typography className={classes.cardheadsub}>trading & demat opening, maintenance charges</Typography>

                        </Grid>
                       <Grid className={classes.card} >
                       <Grid className={classes.card2} >
                                <div className={classes.img_back}>
                                    <img src={picon2} alt='icon' width='30px'></img>
                                </div>
                            </Grid>
                            <Typography className={classes.amount}>₹20/0.05%</Typography>
                            <Typography className={classes.cardhead}>Equity Brokerage</Typography>
                            <Typography className={classes.cardheadsub}>trading & demat opening, maintenance charges</Typography>
                        </Grid>
                        <Grid className={classes.card} >
                        <Grid className={classes.card2} >
                                <div className={classes.img_back}>
                                    <img src={picon3} alt="icon" width='30px'></img>
                                </div>
                            </Grid>
                            <Typography className={classes.amount}>₹20/0.05%</Typography>
                            <Typography className={classes.cardhead}>Futures & Options Brokerage</Typography>
                            <Typography className={classes.cardheadsub}>trading & demat opening, maintenance charges</Typography>
                        </Grid>
                        <Grid className={classes.card} >
                        <Grid className={classes.card2} >
                                <div className={classes.img_back}>
                                    <img src={picon4} alt='icon' width='30px'></img>
                                </div>
                            </Grid>
                            <Typography className={classes.amount}>₹20/0.05%</Typography>
                            <Typography className={classes.cardhead}>Pledge</Typography>
                            <Typography className={classes.cardheadsub}>trading & demat opening, maintenance charges</Typography>
        </Grid>

                    </Grid>

                    <Grid className={classes.leg1}>
                        <Grid className={classes.leg2}>
                            <h1 className={classes.subhead}>Regulatory & Statutory Charges</h1>
                        </Grid>
                        <Grid className={classes.leg3}>
                            <Typography className={classes.clegend}>Color legends</Typography>
                            <div className={classes.greenc} style={{ marginLeft: '26px' }}></div>
                            <Typography className={classes.buy} style={{ padding: '5px' }}>Buy</Typography>
                            <div className={classes.redc} style={{ marginLeft: '26px' }}></div>
                            <Typography className={classes.sell} style={{ padding: '5px' }}>Sell</Typography>


                        </Grid>


                    </Grid>
                    <Hidden only={["xs", "sm"]}>
                    <Grid className={classes.t1}>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.card} style={{ width: '32%', borderBottomLeftRadius: '0px', borderBottomRightRadius: '0px' }} >

                            </Grid>
                            <Grid className={classes.card} style={{ width: '32%' }} >
                                <Typography className={classes.thead}>Equity</Typography>

                            </Grid>
                            <Grid className={classes.card} style={{ width: '32%' }} >
                                <Typography className={classes.thead}>Futures & Options</Typography>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.card} style={{ width: '32%', borderRadius: '0px', borderBottomLeftRadius: '4px', borderBottomRightRadius: '4px' }} >

                            </Grid>
                            <Grid style={{ width: '32%', }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <Typography className={classes.tb}>Equity</Typography>
                                    </Grid>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <Typography className={classes.tb}>Delivery</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '32%' }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <Typography className={classes.tb}>Futures</Typography>
                                    </Grid>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <Typography className={classes.tb}>Options</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid className={classes.t1} >
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.card} style={{ width: '32%', marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Typography className={classes.tb}>STT (Securities Transaction Tax)</Typography>
                            </Grid>
                            <Grid style={{ width: '32%', }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.025%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <div className={classes.redc}></div>
                                        </div>





                                    </Grid>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.1%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '32%' }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.125%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <div className={classes.redc}></div>
                                        </div>


                                    </Grid>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.0625%
                                            </Typography>
                                            <Typography className={classes.tb}>on premium
                                            </Typography>

                                            <div className={classes.redc}></div>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.card} style={{ width: '32%', marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Typography className={classes.tb}>Stamp Duty</Typography>
                            </Grid>
                            <Grid style={{ width: '32%', }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.003%

                                            </Typography>
                                            <div className={classes.greenc}></div>
                                        </div>





                                    </Grid>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.015%

                                            </Typography>


                                            <div className={classes.greenc} ></div>

                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '32%' }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.002%

                                            </Typography>
                                            <div className={classes.greenc}></div>
                                        </div>


                                    </Grid>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.003%
                                            </Typography>

                                            <div className={classes.greenc}></div>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.card} style={{ width: '32%', marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Typography className={classes.tb}>Exchange Transaction charge</Typography>
                            </Grid>
                            <Grid style={{ width: '32%', }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>NSE:0.00325%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <Typography className={classes.tb}>BSE:0.00375%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>





                                    </Grid>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>NSE:0.00325%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <Typography className={classes.tb}>BSE:0.00375%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '32%' }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>NSE:0.0019%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <Typography className={classes.tb}>BSE:0.00%

                                            </Typography>
                                            <Typography className={classes.tb} style={{ color: '#ffffff' }} >on premium
                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid className={classes.card1} style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>NSE:0.05%

                                            </Typography>
                                            <Typography className={classes.tb} >(on premium)
                                            </Typography>

                                            <Typography className={classes.tb}>BSE:0.005%

                                            </Typography>
                                            <Typography className={classes.tb} >(on premium)
                                            </Typography>
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.card} style={{ width: '32%', marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Typography className={classes.tb}>SEBI Turnover charge</Typography>
                            </Grid>
                            <Grid style={{ width: '32%', }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>





                                    </Grid>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '32%' }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.card} style={{ width: '32%', marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Typography className={classes.tb}>DP charges</Typography>
                            </Grid>
                            <Grid style={{ width: '32%', }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                    <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>


                                    </Grid>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>₹13.5 per company

                                            </Typography>


                                            <div className={classes.redc}></div>

                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '32%' }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>₹0

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>



                                    </Grid>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>₹0

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>



                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                            <Grid className={classes.card} style={{ width: '32%', marginTop: '12px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                                <Typography className={classes.tb}>Investor Protection Fund Trust charge</Typography>
                            </Grid>
                            <Grid style={{ width: '32%', }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>NSE:0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>





                                    </Grid>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>NSE:0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '32%' }} >
                                <Grid style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginRight: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>

                                    </Grid>
                                    <Grid className={classes.card1}  style={{ width: '50%', marginTop: '12px', marginLeft: '6px' }}>

                                        <div>
                                            <Typography className={classes.tb}>0.0005%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>


                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

</Hidden>

<Hidden only={["lg", "md", "xl"]}>
<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'0px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderTopLeftRadius:'4px',borderTopRightRadius:'4px'}}>

    </Grid>
    <Grid style={{width:'60%',marginLeft:'6px', background:'#FFFFFF',borderRadius:'4px', display:'flex'}}>
<Typography  className={classes.thead}>Equity</Typography>
</Grid>
</Grid>


<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'0px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderBottomLeftRadius:'4px',borderBottomRightRadius:'4px',}}>
    
    </Grid>
    <Grid style={{marginTop:'5px', width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff'}}>
        <Typography className={classes.tb} style={{padding:'4px'}}>Equity</Typography>
</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff'}}>
        <Typography className={classes.tb} style={{padding:'4px'}}>Delivery</Typography>
</Grid>

</Grid>
</Grid>
    

<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>STT (Securities Transaction Tax)</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>0.025%

                                            </Typography>
                                         
                                            <div className={classes.redc}></div>
                                        </div>

</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>0.1%

                                            </Typography>
                                        
                                            <div style={{ display: 'flex' ,paddingLeft:'5px' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>
</Grid>

</Grid>
</Grid>


<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>Stamp Duty</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>0.003%

                                            </Typography>
                                            <div className={classes.greenc}></div>
                                        </div>


</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>0.015%

                                            </Typography>


                                            <div className={classes.greenc} ></div>

                                        </div>
</Grid>

</Grid>
</Grid>
<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>Exchange Transaction charge</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>NSE:0.00325%

                                            </Typography>
                                         
                                            <Typography className={classes.tb}>BSE:0.00375%

                                            </Typography>
                                         
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>

</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>NSE:0.00325%

                                            </Typography>
                                          
                                            <Typography className={classes.tb}>BSE:0.00375%

                                            </Typography>
                                          
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>
</Grid>

</Grid>
</Grid>
<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>SEBI Turnover charge</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>



</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex',paddingLeft:'5px' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>


</Grid>

</Grid>
</Grid>

<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>DP charges</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>



</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>₹13.5 per company

                                            </Typography>


                                            <div className={classes.redc}></div>

                                        </div>


</Grid>

</Grid>
</Grid>

<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>Investor Protection Fund Trust charge</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>NSE:0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>




</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>NSE:0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>


</Grid>

</Grid>
</Grid>


{/*====== */}
<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'10px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderTopLeftRadius:'4px',borderTopRightRadius:'4px'}}>

    </Grid>
    <Grid style={{width:'60%',marginLeft:'6px', background:'#FFFFFF',borderRadius:'4px', display:'flex'}}>
<Typography  className={classes.thead}>Futures & Options</Typography>
</Grid>
</Grid>


<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'0px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderBottomLeftRadius:'4px',borderBottomRightRadius:'4px',}}>
    
    </Grid>
    <Grid style={{marginTop:'5px', width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff'}}>
        <Typography className={classes.tb} style={{padding:'4px'}}>Futures</Typography>
</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff'}}>
        <Typography className={classes.tb} style={{padding:'4px'}}>Options</Typography>
</Grid>

</Grid>
</Grid>
    

<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>STT (Securities Transaction Tax)</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>0.125%

                                            </Typography>
                                         
                                            <div className={classes.redc}></div>
                                        </div>

</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>0.0625%
                                            </Typography>
                                            <Typography className={classes.tb}>on premium
                                            </Typography>

                                            <div className={classes.redc}></div>
                                        </div>
</Grid>

</Grid>
</Grid>


<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>Stamp Duty</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>0.002%

                                            </Typography>
                                            <div className={classes.greenc}></div>
                                        </div>


</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>0.003%

                                            </Typography>


                                            <div className={classes.greenc} ></div>

                                        </div>
</Grid>

</Grid>
</Grid>
<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>Exchange Transaction charge</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>NSE:0.0019%

                                            </Typography>
                                         
                                            <Typography className={classes.tb}>BSE:0.00%

                                            </Typography>
                                         
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>

</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>NSE:0.05%

                                            </Typography>
                                            <Typography className={classes.tb}>(on premium)
                                            </Typography>

                                            <Typography className={classes.tb}>BSE:0.005%

                                            </Typography>
                                            <Typography className={classes.tb}>(on premium)
                                            </Typography>

                                          
                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div className={classes.greenc} style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>
</Grid>

</Grid>
</Grid>
<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>SEBI Turnover charge</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>



</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex',paddingLeft:'5px' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>


</Grid>

</Grid>
</Grid>

<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>DP charges</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
        <Typography className={classes.tb}>₹0

</Typography>


                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>



</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>₹0

                                            </Typography>


                                            <div className={classes.redc}></div>

                                        </div>


</Grid>

</Grid>
</Grid>

<Grid style={{width:'100%',display:'flex',justifyContent:'center',padding:'5px 16px 0px 16px'}}>
    <Grid style={{width:'40%',background:'#FFFFFF',borderRadius:'4px',}}>
    <Typography className={classes.tb}>Investor Protection Fund Trust charge</Typography>
    </Grid>
    <Grid style={{ width:'60%',marginLeft:'6px',display:'flex',justifyContent:'space-evenly'}}>
        <Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
        <div>
                                            <Typography className={classes.tb}>0.0001%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>




</Grid>
<Grid style={{width:'48%',borderRadius:'4px',background:'#ffffff',padding:'5px'}}>
<div>
                                            <Typography className={classes.tb}>0.0005%

                                            </Typography>

                                            <div style={{ display: 'flex' }}>
                                                <div className={classes.redc}></div>
                                                <div  className={classes.greenc}  style={{ marginLeft: '5px' }}></div>
                                            </div>
                                        </div>


</Grid>

</Grid>
</Grid>

</Hidden>

{/* Demat charges*/}
<Grid className={classes.p1}>
                        <h1 className={classes.subhead}>Demat Charges</h1>
                    </Grid>
                    <Grid  className={classes.p1} marginTop={-3.5}>
                        <Grid className={classes.p2}>
                            <Grid style={{ width: '100%', display: 'flex' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Account Opening
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>NIL</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Statutory Charges at the time of account
                                    </Typography>
                                    <Typography className={classes.Penaltiesh1} >
                                        Opening (Franking Charges)</Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>At Actual</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Demateralization Charges
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>Rs. 100</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Remateralization Charges
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>Rs. 100</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Annual maintenance Charges
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>Rs. 320/- (individual) &nbsp;&nbsp;Rs. 1000/- (Corporate)</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Transaction Debit
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>Rs. 12/- per ISIN</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Transaction Credit
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>NIL</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Margin pledge
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>Rs. 24/- Per Script</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Margin unpledge
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>Rs. 24/- Per Script</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Redemption
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>Rs. 15/- Per Script</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Failed Instruction Charges
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>NIL</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Typography className={classes.clegend} style={{marginTop : "10px"}}>* Above mentioned plans are not inclusive of service tax.</Typography>
                    </Grid>
                    



                    {/*Penalties */}

                    <Grid className={classes.p1} >
                        <h1 className={classes.subhead}>Penalties</h1>
                    </Grid>
                    <Grid  className={classes.p1} marginTop={-3.5}>
                        <Grid className={classes.p2}>
                            <Grid style={{ width: '100%', display: 'flex' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Auto Square-off charges
                                    </Typography>
                                    <Typography className={classes.Penaltiesh1} >
                                        for open intraday positions by system</Typography>

                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>₹50 per position</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Auction
                                    </Typography>
                                    <Typography className={classes.Penaltiesh1} >
                                        If unable to deliver a stock (not in demat)</Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>As per actual penalty by exchange</Typography>
                                </Grid>
                            </Grid>
                            <Grid style={{ width: '100%', display: 'flex', paddingTop: '25px' }}>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh1} >Delayed Payment Charges(DPC)
                                    </Typography>
                                </Grid>
                                <Grid style={{ width: '50%', }}>
                                    <Typography className={classes.Penaltiesh2}>0.045% per day, simple interest, compounded mont</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid className={classes.p1a}>
                          <Grid className={classes.p2}>
                            <Typography className='gst' >18%
                            </Typography>
                            <Grid className={classes.p3}>
                                <Typography className={classes.Penaltiesh1} >On Brokerage, DP charges, Exchange Transaction charges, SEBI Turnover charges and Auto Square-Off charges
                                </Typography>
                            </Grid>
                            <Grid className={classes.p3}>
                                <Typography className={classes.gst} >Physical Delivery of derivatives

                                </Typography>
                            </Grid>
                            <Grid className={classes.p3}>
                                <Typography className={classes.Penaltiesh1} >0.00345% for all groups except R, SS, ST, ZP (1.0%) X,XT,Z (0.1 %) A,B,E, F, FC,G,GC, WT (0.00375%)
                                </Typography>
                            </Grid>


                        </Grid>
                    </Grid>
                
                <Grid style={{ width: '100%',background:'#0B1420' }}>
                    <Footer />
                </Grid>
            </div >
        )
    }
}
export default (withStyles(styles)((pricing)));
